import { Component, OnInit, ViewChild } from '@angular/core';
import { DeviceService } from '../../services/device.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IgxToastComponent } from 'igniteui-angular';
import { Device } from '../../classes/device';

@Component({
  selector: 'app-view-device',
  templateUrl: './view-device.component.html',
  styleUrls: ['./view-device.component.scss']
})
export class ViewDeviceComponent implements OnInit {

  @ViewChild(IgxToastComponent, { static: true })
  public toast: IgxToastComponent;

  public device: Device;
  public loading = true;
  public hotelId: number;

  public constructor(
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  public ngOnInit(): void {
    this.route.params
      .subscribe(
        params => {
          this.hotelId = params.hotelId;
          const id = params.id;
          this.deviceService
            .findById(id)
            .subscribe(
              device => {
                this.device = device;
                this.loading = false;
              }
            );
        }
      );
  }

  public deleteDevice(): void {
    this.deviceService
      .delete(this.device.id)
      .subscribe(
        res => {
          this.showMessage('Device successfully deleted.');
          this.router.navigateByUrl('/hotel', { replaceUrl: true });
        },
        err => {
          this.showMessage(err.error.message);
        }
      );
  }

  public changeStatus(device: Device): void {
    if (device.active) {
      this.deviceService
        .executeCommand(device.id, 'DEACTIVATE')
        .subscribe(
          res => {
            device.status = 'INACTIVE';
            device.active = false;
          }
        );
    } else {
      this.deviceService
        .executeCommand(device.id, 'ACTIVATE')
        .subscribe(
          res => {
            device.status = 'ACTIVE';
            device.active = true;
          }
        );
    }
  }

  private showMessage(message: string): void {
    this.toast.message = message;
    this.toast.show();
  }

}
