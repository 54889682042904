import { Component, OnInit, ViewChild } from '@angular/core';
import { IgxToastComponent } from 'igniteui-angular';
import { PreviewHotel } from '../../classes/preview-hotel';
import { PreviewCategory } from '../../classes/preview-category';
import { Item } from '../../classes/item';
import { ItemService } from '../../services/item.service';
import { HotelService } from '../../services/hotel.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateItemRequest } from '../../classes/create-item';
import { ItemLanguageContent } from '../../classes/item-language-content';
import { Location } from '@angular/common';

@Component({
  selector: 'app-view-item',
  templateUrl: './view-item.component.html',
  styleUrls: ['./view-item.component.scss']
})
export class ViewItemComponent implements OnInit {

  @ViewChild(IgxToastComponent, { static: true })
  public toast: IgxToastComponent;

  public hotels: PreviewHotel[] = [];
  public selectedHotel: PreviewHotel;
  public selectedCategory: PreviewCategory;

  public item: Item;
  public loading = true;

  public constructor(
    private itemService: ItemService,
    private hotelService: HotelService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router
  ) { }

  public ngOnInit(): void {
    this.route
      .params
      .subscribe(
        params => {
          const id = params.id;
          this.itemService
            .findById(id)
            .subscribe(
              item => {
                this.item = item;
                this.loading = false;
                this.hotelService
                .findAll()
                .subscribe(
                  hotels => {
                    this.hotels = hotels;
                    this.selectedHotel = hotels.filter(hotel => hotel.id === item.hotelId)[0];
                  }
                )
              }
            );
        }
      )
  }

  public editItem(): void {
    this.router.navigateByUrl(`/hotel/item/edit/${this.item.id}`);
  }

  public deleteItem(): void {
    this.itemService
      .delete(this.item.id)
      .subscribe(
        res => {
          this.showMessage('Item successfully deleted.');
          this.backToHotel();
        },
        err => {
          this.showMessage(err.error.message);
        }
      );
  }

  public backToHotel(): void {
    this.location.back();
  }

  public copyItem(): void {
    const createItemRequest = new CreateItemRequest();
    createItemRequest.categoryId = this.selectedCategory.id;
    createItemRequest.expireOn = new Date(this.item.expireOn);
    createItemRequest.availabilities = [];
    this.item.availabilities.forEach(a => {
      const availableFrom = new Date(`2020-12-05T${a.availableFrom}`);
      const availableTo = new Date(`2020-12-05T${a.availableTo}`);
      createItemRequest.availabilities.push({ 
        availableFrom,
        availableTo,
        deviceId: a.device.id,
        monday: a.monday,
        tuesday: a.tuesday,
        wednesday: a.wednesday,
        thursday: a.thursday,
        friday: a.friday,
        saturday: a.saturday,
        sunday: a.sunday,
      });
    });
    createItemRequest.hasFee = this.item.hasFee;
    createItemRequest.longDescription = this.item.longDescription;
    createItemRequest.name = this.item.name;
    createItemRequest.price = this.item.price;
    createItemRequest.quantity = this.item.quantity;
    createItemRequest.shortDescription = this.item.shortDescription;
    createItemRequest.type = this.item.type;
    createItemRequest.customWarningLimits = true;
    createItemRequest.allowPreOrder = this.item.allowPreOrder;
    createItemRequest.stockQuantityLimitWarning = this.item.stockQuantityLimitWarning;
    createItemRequest.expirationPeriodLimitWarning = this.item.expirationPeriodLimitWarning;
    const languageContentList: {content: string, field: string, languageCode: string}[] = this.item.languageContentList;
    const languageContent: ItemLanguageContent = new ItemLanguageContent();
    languageContent.nameEn = languageContentList.filter(i => i.languageCode === 'EN' && i.field === 'name')[0].content;
    languageContent.nameFr = languageContentList.filter(i => i.languageCode === 'FR' && i.field === 'name')[0].content;
    languageContent.nameIt = languageContentList.filter(i => i.languageCode === 'IT' && i.field === 'name')[0].content;
    languageContent.shortDescriptionEn = languageContentList.filter(i => i.languageCode === 'EN' && i.field === 'short_description')[0].content;
    languageContent.shortDescriptionFr = languageContentList.filter(i => i.languageCode === 'FR' && i.field === 'short_description')[0].content;
    languageContent.shortDescriptionIt = languageContentList.filter(i => i.languageCode === 'IT' && i.field === 'short_description')[0].content;
    languageContent.longDescriptionEn = languageContentList.filter(i => i.languageCode === 'EN' && i.field === 'long_description')[0].content;
    languageContent.longDescriptionFr = languageContentList.filter(i => i.languageCode === 'FR' && i.field === 'long_description')[0].content;
    languageContent.longDescriptionIt = languageContentList.filter(i => i.languageCode === 'IT' && i.field === 'long_description')[0].content;
    createItemRequest.languageContent = languageContent;
    this.itemService
      .create(createItemRequest)
      .subscribe(
        res => {
          this.showMessage('Item copied successfully.');
          this.router.navigateByUrl(`/hotel/item/${res.id}`);
        }
      );
  }

  private showMessage(message: string): void {
    this.toast.message = message;
    this.toast.show();
  }

}
