
export class EditHotelRequest {
    languageId: number;
    feeAmount: number;
    serviceAvailableFrom: Date;
    serviceAvailableTo: Date;
    checkIn: Date;
    checkOut: Date;
    stockQuantityLimitWarning: number;
    expirationPeriodLimitWarning: number;
    unlockCode: string;
    dayChange: Date;
}
