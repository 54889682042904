import { Component, OnInit, ViewChild } from '@angular/core';
import { IgxToastComponent } from 'igniteui-angular';
import { HotelService } from '../../services/hotel.service';
import { Location } from '@angular/common';
import { CreateCategoryRequest } from '../../classes/create-category';
import { PreviewHotel } from '../../classes/preview-hotel';
import { CategoryService } from 'src/app/hotel/services/category.service';

@Component({
  selector: 'app-create-category',
  templateUrl: './create-category.component.html',
  styleUrls: ['./create-category.component.scss']
})
export class CreateCategoryComponent implements OnInit {

  @ViewChild(IgxToastComponent, { static: true })
  public toast: IgxToastComponent;

  public category: CreateCategoryRequest = new CreateCategoryRequest();
  public loading = false;
  public hotels: PreviewHotel[] = [];
  public selectedHotel: PreviewHotel = { id: 0, name: '', code: '', functionalities: [] }

  public constructor(
    private categoryService: CategoryService,
    private hotelService: HotelService,
    private location: Location
  ) { }

  public ngOnInit(): void {
    this.hotelService
      .findAll()
      .subscribe(
        hotels => {
          this.hotels = hotels;
          const id = localStorage.getItem('hotelId');
          this.selectedHotel = hotels.filter(hotel => hotel.id == Number.parseInt(id))[0];
        }
      );
  }

  public onSave(): void {
    this.loading = true;
    this.categoryService
      .create(this.category)
      .subscribe(
        res => {
          this.loading = false;
          this.location.back();
        },
        err => {
          this.loading = false;
          this.toast.message = err.error.message;
          this.toast.show();
        }
      );
  }

  public onCancel(): void {
    this.location.back();
  }

}
