import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HotelService } from '../../services/hotel.service';
import { IgxToastComponent } from 'igniteui-angular';
import { Location } from '@angular/common';
import { Hotel } from '../../classes/hotel';
import { EditHotelRequest } from '../../classes/edit-hotel';
import { PreviewLanguage } from '../../classes/preview-language';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-edit-hotel',
  templateUrl: './edit-hotel.component.html',
  styleUrls: ['./edit-hotel.component.scss']
})
export class EditHotelComponent implements OnInit {

  @ViewChild(IgxToastComponent, { static: true })
  public toast: IgxToastComponent;

  public hotel: Hotel;
  public editHotel: EditHotelRequest = new EditHotelRequest();
  public languages: PreviewLanguage[] = [];
  public loading = true;

  public constructor(
    private activatedRoute: ActivatedRoute,
    private hotelService: HotelService,
    private location: Location,
    private router: Router,
    private languageService: LanguageService
  ) { }

  public ngOnInit(): void {
    this.activatedRoute
      .params
      .subscribe(
        params => {
          const id = params.id;
          this.hotelService
            .findById(id)
            .subscribe(
              hotel => {
                this.hotel = hotel;
                this.editHotel = this.createEditHotelRequest(hotel);
                this.loading = false;
              }
            );
        }
      );
    this.languageService
      .findAll()
      .subscribe(
        languages => {
          this.languages = languages;
        }
      )
  }

  public deleteHotel(hotelCode: string): void {
    if (this.hotel.code !== hotelCode) {
      this.showMessage('The hotel code you entered is wrong!');
      return;
    }
    this.loading = true;
    this.hotelService
      .delete(this.hotel.id)
      .subscribe(
        res => {
          this.showMessage('Hotel successfully deleted.');
          this.router.navigateByUrl('/hotel', { replaceUrl: true });
        },
        err => {
          this.showMessage(err.error.message);
        }
      );
  }

  public onSave(): void {
    this.loading = true;
    this.hotelService
      .edit(this.editHotel, this.hotel.id)
      .subscribe(
        res => {
          this.loading = false;
          this.location.back();
        },
        err => {
          this.loading = false;
          this.toast.message = err.error.message;
          this.toast.show();
        }
      );
  }

  public onCancel(): void {
    this.location.back();
  }

  private parseHour(input: string): Date {
    const date = new Date(Date.now());
    const parts = input.split(/[\s:]+/);
    const hour = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);
    date.setHours(hour, minutes);
    return date;
  }

  private showMessage(message: string): void {
    this.toast.message = message;
    this.toast.show();
  }

  private createEditHotelRequest(hotel: Hotel): EditHotelRequest {
    const editHotel = new EditHotelRequest();
    editHotel.languageId = hotel.defaultLanguage.id;
    editHotel.feeAmount = hotel.feeAmount;
    editHotel.serviceAvailableFrom = this.parseHour(hotel.serviceAvailableFrom);
    editHotel.serviceAvailableTo = this.parseHour(hotel.serviceAvailableTo);
    editHotel.checkIn = this.parseHour(hotel.checkIn);
    editHotel.checkOut = this.parseHour(hotel.checkOut);
    editHotel.dayChange = this.parseHour(hotel.dayChange);
    editHotel.expirationPeriodLimitWarning = hotel.expirationPeriodLimitWarning;
    editHotel.stockQuantityLimitWarning = hotel.stockQuantityLimitWarning;
    editHotel.unlockCode = hotel.unlockCode;
    return editHotel;
  }

}
