<igx-list style="height: auto;" class="mt-1">
  <ng-template igxEmptyList>
      <p class="center">No orders found.</p>
  </ng-template>
  <ng-template igxDataLoading>
      <p class="center">Patience, we are currently loading your data...</p>
  </ng-template>
  <igx-list-item
      (click)="viewOrder(order)"
      *ngFor="let order of orders">
      <div
        [igxTooltipTarget]="tooltipRef"
        [style.background-color]="getBackgroundColor(order)"
        style="border-radius: 50%; width: 10px; height: 10px;"></div>
      <span igxListLineTitle>Order: {{order.name}}</span>
      <span igxListLineSubTitle>Total: € {{order.total.toFixed(2)}}</span>
      <div #tooltipRef="tooltip" igxTooltip>
        {{order.status}}
      </div>
  </igx-list-item>
</igx-list>
<igx-paginator #paginator
  class="mt-1"
  [selectOptions]="selectOptions"
  [page]="0"
  [totalRecords]="total"
  [(perPage)]="sizePerPage">
</igx-paginator>
<igx-linear-bar class="loading" *ngIf="loading" indeterminate="true"></igx-linear-bar>
