import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { PreviewCategory } from '../classes/preview-category';
import { CreateCategoryRequest } from '../classes/create-category';
import { EditCategoryRequest } from '../classes/edit-category';

@Injectable()
export class CategoryService {

  public constructor(
    private apiService: ApiService,
    private userService: UserService
  ) { }

  public updateSequence(categories: PreviewCategory[]): Observable<any> {
    const token = this.userService.get() && this.userService.get().token || '';
    const requestCategories: { categoryId: number, sequence: number }[] = [];
    categories.forEach((category, index) => {
      requestCategories.push({ categoryId: category.id, sequence: index });
    });
    return this.apiService.post('/categories/sequence', { categories: requestCategories }, token);
  }

  public findById(id: number): Observable<any> {
    const token = this.userService.get() && this.userService.get().token || '';
    return this.apiService.get(`/categories/${id}`, token);
  }

  public create(category: CreateCategoryRequest): Observable<any> {
    const token = this.userService.get() && this.userService.get().token || '';
    category.name = category.languageContent.nameEn;
    return this.apiService.post('/categories', category, token);
  }

  public update(category: EditCategoryRequest, id: number): Observable<any> {
    const token = this.userService.get() && this.userService.get().token || '';
    category.name = category.languageContent.nameEn;
    return this.apiService.put(`/categories/${id}`, category, token);
  }

  public delete(category: PreviewCategory): Observable<any> {
    const token = this.userService.get() && this.userService.get().token || '';
    return this.apiService.delete(`/categories/${category.id}`, token);
  }

  public findAllByFunctionality(functionalityId: number): Observable<PreviewCategory[]> {
    const token = this.userService.get() && this.userService.get().token || '';
    return this.apiService.get(`/categories/functionality/${functionalityId}`, token);
  }

}
