import { CategoryLanguageContent } from './category-language-content';

export class CreateCategoryRequest {
    name: string;
    functionalityId: number;
    sequence: number;
    languageContent: CategoryLanguageContent;

    constructor() {
        this.name = '';
        this.sequence = 0;
        this.functionalityId = null;
        this.languageContent = new CategoryLanguageContent();
    }
}