import { Component, OnInit, ViewChild } from '@angular/core';
import { IgxFilterOptions, IgxListComponent } from 'igniteui-angular';
import { Router } from '@angular/router';
import { PreviewHotel } from './classes/preview-hotel';
import { HotelService } from './services/hotel.service';

@Component({
  selector: 'app-hotel',
  templateUrl: './hotel.component.html',
  styleUrls: ['./hotel.component.scss']
})
export class HotelComponent implements OnInit {

  @ViewChild("hotelList", { static: true })
  public hotelList: IgxListComponent;
  public hotels: PreviewHotel[] = [];
  public searchHotel: string;

  public constructor(
    private hotelService: HotelService,
    private router: Router
  ) { }

  public ngOnInit(): void {
    this.hotelList.isLoading = true;
    this.hotelService
      .findAll()
      .subscribe(
        hotels => {
          this.hotels = hotels.sort((a, b) => a.name > b.name ? 1 : -1);
          this.hotelList.isLoading = false;
        }
      );
  }

  public addHotel(): void {
    this.router.navigateByUrl('/hotel/create');
  }

  public editHotel(hotel: PreviewHotel): void {
    this.router.navigateByUrl(`/hotel/edit/${hotel.id}`);
  }

  public viewHotel(hotel: PreviewHotel): void {
    this.router.navigateByUrl(`/hotel/${hotel.id}`);
  }

  get filterHotels() {
    const fo = new IgxFilterOptions();
    fo.key = "name";
    fo.inputValue = this.searchHotel;
    return fo;
  }

}
