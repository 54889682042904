import { Component, OnInit, ViewChild } from '@angular/core';
import { IgxToastComponent } from 'igniteui-angular';
import { HotelService } from '../../services/hotel.service';
import { Location } from '@angular/common';
import { CreateItemRequest } from '../../classes/create-item';
import { PreviewHotel } from '../../classes/preview-hotel';
import { ItemService } from '../../services/item.service';
import { PreviewDevice } from '../../classes/preview-device';
import { DeviceService } from '../../services/device.service';
import { ItemAvailability } from '../../classes/item.availability';

@Component({
  selector: 'app-create-item',
  templateUrl: './create-item.component.html',
  styleUrls: ['./create-item.component.scss']
})
export class CreateItemComponent implements OnInit {

  @ViewChild(IgxToastComponent, { static: true })
  public toast: IgxToastComponent;

  public item: CreateItemRequest = new CreateItemRequest();
  public loading = false;
  public hotels: PreviewHotel[] = [];
  public selectedHotel: PreviewHotel = { functionalities: [], id: 0, name: '', code: ''};

  public devices: PreviewDevice[] = [];

  public constructor(
    private itemService: ItemService,
    private hotelService: HotelService,
    private location: Location,
    private deviceService: DeviceService
  ) { }

  public ngOnInit(): void {
    this.hotelService
      .findAll()
      .subscribe(
        hotels => {
          this.hotels = hotels;
          const id = localStorage.getItem('hotelId');
          this.selectedHotel = hotels.filter(hotel => hotel.id == Number.parseInt(id))[0];
          this.deviceService
            .findAllReceptions(this.selectedHotel.id)
            .subscribe(
              devices => {
                this.devices = devices;
              }
            )
        }
      );
  }

  public onSave(): void {
    this.loading = true;
    if (this.item.type === 'SERVICE') {
      this.item.quantity = 1;
    } else if (this.item.type === 'PRODUCT') {
      this.item.isForSale = true;
    }
    if (!this.item.isForSale) {
      this.item.allowPreOrder = false;
    }
    this.itemService
      .create(this.item)
      .subscribe(
        res => {
          this.loading = false;
          this.location.back();
        },
        err => {
          this.loading = false;
          this.toast.message = err.error.message;
          this.toast.show();
        }
      );
  }

  public trackAvailability(availability: ItemAvailability): string {
    return `${availability.availableFrom}-${availability.availableTo}`;
  }

  public addAvailabilityRow(): void {
    this.item.availabilities.push(
      { 
        availableFrom: new Date(Date.now()), 
        availableTo: new Date(Date.now()), 
        deviceId: this.devices[0].id,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true,
      }
    );
    this.item.availabilities = this.item.availabilities.sort(this.compare);
  }

  public removeAvailabilityRow(row: any): void {
    const index = this.item.availabilities.indexOf(row, 0);
    if (index > -1) {
      this.item.availabilities.splice(index, 1);
    }
    this.item.availabilities = this.item.availabilities.sort(this.compare);
  }

  public onCancel(): void {
    this.location.back();
  }

  private compare(a: any, b: any): number {
    var time1 = parseFloat(a.availableFrom.toTimeString().substring(0, 5).replace(':','.').replace(/[^\d.-]/g, ''));
    var time2 = parseFloat(b.availableFrom.toTimeString().substring(0, 5).replace(':','.').replace(/[^\d.-]/g, ''));
    if (time1 < time2) return -1;
    if (time1 > time2) return 1;
    return 0;
  }

}
