<igx-select class="mt-1" *ngIf="hotel" [(ngModel)]="selectedFunctionality" (onSelection)="onSelectFunctionality($event.newSelection.value)">
  <label igxLabel>Department</label>
  <igx-select-item
      *ngFor="let functionality of hotel.functionalities" [value]="functionality">
      {{functionality.name}}
  </igx-select-item>
</igx-select>
<igx-list cdkDropList (cdkDropListDropped)="dropCategory($event)" class="mt-1">
  <ng-template igxEmptyList>
      <p class="center">There are no categories found for this department.</p>
  </ng-template>
  <ng-template igxDataLoading>
      <p class="center">Patience, we are currently loading your data...</p>
  </ng-template>
  <igx-list-item cdkDrag *ngFor="let category of selectedFunctionality.categories">
      <span igxListLineTitle>{{category.name}}</span>
      <igx-icon igxListAction (click)="editCategory(category)" [igxTooltipTarget]="edit">edit</igx-icon>
      <igx-icon igxListAction (click)="categoryForDelete = category; dialog2.open()" [igxTooltipTarget]="delete">delete</igx-icon>
  </igx-list-item>
  <div #edit="tooltip" igxTooltip>
      Edit
  </div>
  <div #delete="tooltip" igxTooltip>
      Delete
  </div>
</igx-list>

<igx-dialog #dialog2 title="Confirmation"
  leftButtonLabel="Cancel"
  (onLeftButtonSelect)="dialog2.close()"
  rightButtonLabel="OK"
  (onRightButtonSelect)="deleteCategory(); dialog2.close()"
  message="Please note that this will also delete all of the items related to this. Are you sure you want to delete this category?">
</igx-dialog>
