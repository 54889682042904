import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IgxDialogComponent, IgxToastComponent } from 'igniteui-angular';
import { Hotel } from '../../classes/hotel';
import { HotelService } from '../../services/hotel.service';

@Component({
  selector: 'app-view-hotel',
  templateUrl: './view-hotel.component.html',
  styleUrls: ['./view-hotel.component.scss']
})
export class ViewHotelComponent implements OnInit, OnDestroy {

  @ViewChild(IgxToastComponent, { static: true })
  public toast: IgxToastComponent;
  @ViewChild("uploadedApk", { static: true })
  public dialog: IgxDialogComponent;

  public hotel: Hotel;
  public loading = true;
  public apkVersion: string;
  public apkFile: string;
  public tabSelectedIndex: string = "0";
  public uploadApkRequest = this.builder.group({
    version: ['', Validators.required],
    apk: ['', Validators.required],
    code: ['', Validators.required],
    role: ['GUEST', Validators.required]
  });
  public itemsPerPage: number = 5;

  private refreshRef: any;

  public constructor(
    private hotelService: HotelService,
    private route: ActivatedRoute,
    private router: Router,
    private builder: FormBuilder
  ) { }

  public ngOnInit(): void {
    this.route
      .params
      .subscribe(
        params => {
          const id = params.id;
          this.hotelService
            .findById(id)
            .subscribe(
              hotel => {
                hotel.functionalities = hotel.functionalities.sort((a, b) => a.id - b.id)
                this.hotel = hotel;
                this.loading = false;
                localStorage.setItem('hotelId', id);
                localStorage.setItem('hotelName', hotel.name);
                this.uploadApkRequest.controls.code.setValue(hotel.code);
              }
            );
        }
      )
    this.tabSelectedIndex = localStorage.getItem('tabs-selected-index') || "0";
    this.registerAutoRefresh();
  }

  public ngOnDestroy(): void {
    clearInterval(this.refreshRef)
  }

  public registerAutoRefresh(): void {
    this.refreshRef = setInterval(() => {
      this.loading = true;
      const id = this.hotel.id;
      this.hotelService
        .findById(id)
        .subscribe(
          hotel => {
            hotel.functionalities = hotel.functionalities.sort((a, b) => a.id - b.id)
            this.hotel = hotel;
            this.loading = false;
          }
        );
    }, 10000);
  }

  public onTabItemSelected(): void {
    localStorage.setItem('tabs-selected-index', this.tabSelectedIndex);
  }

  public editHotel(): void {
    this.router.navigateByUrl(`/hotel/edit/${this.hotel.id}`);
  }

  public openOrderHistory(): void {
    this.router.navigateByUrl(`/hotel/order/history`);
  }

  public deleteHotel(hotelCode: string): void {
    if (this.hotel.code !== hotelCode) {
      this.showMessage('The hotel code you entered is wrong!');
      return;
    }
    this.loading = true;
    this.hotelService
      .delete(this.hotel.id)
      .subscribe(
        res => {
          this.showMessage('Hotel successfully deleted.');
          this.router.navigateByUrl('/hotel', { replaceUrl: true });
        },
        err => {
          this.showMessage(err.error.message);
        }
      );
  }

  public onDeleteCategory(event: {deleted: boolean, message: string}): void {
    if (event.deleted) {
      this.hotelService
        .findById(this.hotel.id)
        .subscribe(
          hotel => {
            this.hotel = hotel;
          }
        );
    }
    this.showMessage(event.message);
  }

  public addNew(route: string): void {
    this.router.navigateByUrl(route);
  }

  public uploadApk(): void {
    if (this.uploadApkRequest.valid) {
      this.showMessage("APK is uploading...");
      this.loading = true;
      const request = this.uploadApkRequest.value;
      this.hotelService
        .uploadApk(request)
        .subscribe(
          res => {
            this.loading = false;
            this.dialog.open();
            if (request.role === 'GUEST') {
              this.hotel.guestAppVersion = this.uploadApkRequest.controls.version.value;
            } else {
              this.hotel.receptionAppVersion = this.uploadApkRequest.controls.version.value;
            }
          },
          err => {
            this.showMessage("Something went wrong!");
          }
        );
    } else {
      this.showMessage("Please fill in the version and upload a valid apk!");
    }
  }

  public validateApk(event: any): void {
    if (event.target.value) {
      const file = event.target.files[0];
      const type = file.type;
      if (type === 'application/vnd.android.package-archive') {
        this.changeFile(file).then((base64: string): any => {
          this.uploadApkRequest.controls.apk.setValue(base64);
        });
      } else {
        this.showMessage("Please upload a valid apk!");
      }
    }
  }

  private changeFile(file: any) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
  }

  private showMessage(message: string): void {
    this.toast.message = message;
    this.toast.show();
  }

}
