import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Item } from '../../classes/item';
import { ItemAvailability } from '../../classes/item.availability';

@Component({
  selector: 'app-view-availabilities',
  templateUrl: './view-availabilities.component.html',
  styleUrls: ['./view-availabilities.component.scss']
})
export class ViewAvailabilitiesComponent implements OnInit {

  @Input()
  public item: Item;

  public availabilities: ItemAvailability[] = [];

  public loading = true;
  public page: number = 0;
  public total: number = 100;
  public selectOptions: number[] = [20, 50, 100];
  public sizePerPage: number = 20;

  constructor() { }

  ngOnInit(): void {
    this.availabilities = this.item
      .availabilities
      .sort(this.compare);
  }

  public fetchAvailableDays(availability: ItemAvailability): string {
    let availabilityString = '';
    availabilityString += availability.monday ? 'Monday ' : '';
    availabilityString += availability.tuesday ? 'Tuesday ' : '';
    availabilityString += availability.wednesday ? 'Wednesday ' : '';
    availabilityString += availability.thursday ? 'Thursday ' : '';
    availabilityString += availability.friday ? 'Friday ' : '';
    availabilityString += availability.saturday ? 'Saturday ' : '';
    availabilityString += availability.sunday ? 'Sunday' : '';
    return availabilityString
  }

  private compare(a: ItemAvailability, b: ItemAvailability): number {
    var time1 = parseFloat(a.availableFrom.replace(':','.').replace(/[^\d.-]/g, ''));
    var time2 = parseFloat(b.availableFrom.replace(':','.').replace(/[^\d.-]/g, ''));
    if (time1 < time2) return -1;
    if (time1 > time2) return 1;
    return 0;
  } 

}
