import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { Order } from '../classes/order';
import { OrdersResponse } from '../classes/orders.response';

@Injectable()
export class OrderService {

  public constructor(
    private apiService: ApiService,
    private userService: UserService
  ) { }

  public findAllFiltered(filter: {
    hotelId: number,
    page?: number,
    size?: number,
    deviceId?: number,
    itemId?: number,
    status?: string,
    room?: string,
    completedOnStart?: string,
    completedOnEnd?: string,
    createdOnStart?: string,
    createdOnEnd?: string,
    confirmedOnStart?: string,
    confirmedOnEnd?: string
  }): Observable<OrdersResponse> {
    const zoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let urlPath = `/orders?hotelId=${filter.hotelId}&zoneId=${zoneId}`;
    if (filter.page) {
      urlPath = urlPath.concat(`&page=${filter.page}`)
    }
    if (filter.size) {
      urlPath = urlPath.concat(`&size=${filter.size}`)
    }
    if (filter.deviceId) {
      urlPath = urlPath.concat(`&deviceId=${filter.deviceId}`)
    }
    if (filter.itemId) {
      urlPath = urlPath.concat(`&itemId=${filter.itemId}`)
    }
    if (filter.status) {
      urlPath = urlPath.concat(`&status=${filter.status}`)
    }
    if (filter.room) {
      urlPath = urlPath.concat(`&room=${filter.room}`)
    }
    if (filter.completedOnStart) {
      urlPath = urlPath.concat(`&completedOnStart=${filter.completedOnStart}`)
    }
    if (filter.completedOnEnd) {
      urlPath = urlPath.concat(`&completedOnEnd=${filter.completedOnEnd}`)
    }
    if (filter.createdOnStart) {
      urlPath = urlPath.concat(`&createdOnStart=${filter.createdOnStart}`)
    }
    if (filter.createdOnEnd) {
      urlPath = urlPath.concat(`&createdOnEnd=${filter.createdOnEnd}`)
    }
    if (filter.confirmedOnStart) {
      urlPath = urlPath.concat(`&confirmedOnStart=${filter.confirmedOnStart}`)
    }
    if (filter.confirmedOnEnd) {
      urlPath = urlPath.concat(`&confirmedOnEnd=${filter.confirmedOnEnd}`)
    }
    const token = this.userService.get() && this.userService.get().token || '';
    return this.apiService.get(urlPath, token);
  }

  public findById(id: number): Observable<Order> {
    const token = this.userService.get() && this.userService.get().token || '';
    return this.apiService.get(`/orders/${id}`, token);
  }

}
