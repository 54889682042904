<igx-list style="height: auto;" class="mt-1">
  <ng-template igxEmptyList>
      <p class="center">There are no availabilities linked with this item.</p>
  </ng-template>
  <ng-template igxDataLoading>
      <p class="center">Patience, we are currently loading your data...</p>
  </ng-template>
  <igx-list-item *ngFor="let availability of availabilities">
    <span igxListLineTitle>{{ availability.device.room }} <-> {{ availability.availableFrom }} - {{ availability.availableTo }}</span>
    <span igxListLineSubTitle>{{ fetchAvailableDays(availability) }}</span>
  </igx-list-item>
</igx-list>
<igx-linear-bar class="loading" *ngIf="loading" indeterminate="true"></igx-linear-bar>
