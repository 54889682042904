import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IgxToastComponent } from 'igniteui-angular';
import { CreateHotelRequest } from '../../classes/create-hotel';
import { PreviewLanguage } from '../../classes/preview-language';
import { HotelService } from '../../services/hotel.service';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-create-hotel',
  templateUrl: './create-hotel.component.html',
  styleUrls: ['./create-hotel.component.scss']
})
export class CreateHotelComponent implements OnInit {

  @ViewChild(IgxToastComponent, { static: true })
  public toast: IgxToastComponent;

  public hotel: CreateHotelRequest = new CreateHotelRequest();
  public languages: PreviewLanguage[] = [];
  public loading = false;

  public constructor(
    private hotelService: HotelService,
    private router: Router,
    private languageService: LanguageService
  ) { }

  public ngOnInit(): void {
    this.languageService
      .findAll()
      .subscribe(
        languages => {
          this.languages = languages;
        }
      )
  }

  public onSave(): void {
    this.loading = true;
    this.hotelService
      .create(this.hotel)
      .subscribe(
        res => {
          this.router.navigateByUrl('/hotel', { replaceUrl: true });
          this.loading = false;
        },
        err => {
          this.loading = false;
          this.toast.message = err.error.message;
          this.toast.show();
        }
      );
  }
}
