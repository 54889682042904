<div class="wrapper">

  <igx-card>
      <igx-card-header>
          <h3 igxCardHeaderTitle>Edit hotel</h3>
          <h5 igxCardHeaderSubtitle *ngIf="hotel">{{hotel.name}} - {{hotel.code}}</h5>
      </igx-card-header>

      <igx-card-content>
          <form #hotelForm="ngForm">
              <igx-input-group>
                <label igxLabel>Unlock Code</label>
                <input igxInput required [(ngModel)]="editHotel.unlockCode" name="unlockCode"/>
              </igx-input-group>
              <igx-select required [(ngModel)]="editHotel.languageId" name="languageId">
                <label igxLabel>Default Language</label>
                <igx-select-item *ngFor="let language of languages" [value]="language.id">
                    {{language.name}}
                </igx-select-item>
              </igx-select>
              <div igxLayout>
                <igx-input-group igxFlex>
                    <label igxLabel>Quantity left in stock reminder</label>
                    <input igxInput required [(ngModel)]="editHotel.stockQuantityLimitWarning" name="stockQuantityLimitWarning"/>
                </igx-input-group>
                <igx-input-group igxFlex>
                    <label igxLabel>Days before expiration reminder</label>
                    <input igxInput required [(ngModel)]="editHotel.expirationPeriodLimitWarning" name="expirationPeriodLimitWarning"/>
                </igx-input-group>
              </div>              
              <div igxLayout>
                <igx-input-group igxFlex>
                    <igx-prefix>
                        <igx-icon>euro_symbol</igx-icon>
                    </igx-prefix>
                    <label igxLabel>Room Service Fee Amount</label>
                    <input igxInput required type="number" [(ngModel)]="editHotel.feeAmount" name="feeAmount" />
                </igx-input-group>
                <igx-time-picker igxFlex [(ngModel)]="editHotel.dayChange" format="HH:mm" name="dayChange">
                    <ng-template igxTimePickerTemplate let-openDialog="openDialog" let-value="value" let-displayTime="displayTime">
                        <igx-input-group (click)="openDialog(dayChange)">
                            <igx-prefix>
                                <igx-icon>access_time</igx-icon>
                            </igx-prefix>
                            <label igxLabel>DayChange</label>
                            <input required #dayChange igxInput [value]="displayTime"/>
                        </igx-input-group>
                    </ng-template>
                </igx-time-picker>
              </div>
              <div igxLayout>
                  <igx-time-picker igxFlex [(ngModel)]="editHotel.serviceAvailableFrom" format="HH:mm" name="serviceAvailableFrom">
                      <ng-template igxTimePickerTemplate let-openDialog="openDialog" let-value="value" let-displayTime="displayTime">
                          <igx-input-group (click)="openDialog(serviceAvailableFrom)">
                              <igx-prefix>
                                  <igx-icon>access_time</igx-icon>
                              </igx-prefix>
                              <label igxLabel>Service Available From</label>
                              <input required #serviceAvailableFrom igxInput [value]="displayTime"/>
                          </igx-input-group>
                      </ng-template>
                  </igx-time-picker>
                  <igx-time-picker igxFlex [(ngModel)]="editHotel.serviceAvailableTo" format="HH:mm" name="serviceAvailableTo">
                      <ng-template igxTimePickerTemplate let-openDialog="openDialog" let-value="value" let-displayTime="displayTime">
                          <igx-input-group (click)="openDialog(serviceAvailableTo)">
                              <igx-prefix>
                                  <igx-icon>access_time</igx-icon>
                              </igx-prefix>
                              <label igxLabel>Service Available To</label>
                              <input required #serviceAvailableTo igxInput [value]="displayTime"/>
                          </igx-input-group>
                      </ng-template>
                  </igx-time-picker>
              </div>
              <div igxLayout>
                  <igx-time-picker igxFlex [(ngModel)]="editHotel.checkIn" format="HH:mm" name="checkIn">
                      <ng-template igxTimePickerTemplate let-openDialog="openDialog" let-value="value" let-displayTime="displayTime">
                          <igx-input-group (click)="openDialog(checkIn)">
                              <igx-prefix>
                                  <igx-icon>today</igx-icon>
                              </igx-prefix>
                              <label igxLabel>Check In</label>
                              <input required #checkIn igxInput [value]="displayTime"/>
                          </igx-input-group>
                      </ng-template>
                  </igx-time-picker>
                  <igx-time-picker igxFlex [(ngModel)]="editHotel.checkOut" format="HH:mm" name="checkOut">
                      <ng-template igxTimePickerTemplate let-openDialog="openDialog" let-value="value" let-displayTime="displayTime">
                          <igx-input-group (click)="openDialog(checkOut)">
                              <igx-prefix>
                                  <igx-icon>today</igx-icon>
                              </igx-prefix>
                              <label igxLabel>Check Out</label>
                              <input required #checkOut igxInput [value]="displayTime"/>
                          </igx-input-group>
                      </ng-template>
                  </igx-time-picker>
              </div>
          </form>
      </igx-card-content>

      <igx-card-actions>
          <button igxButton igxRipple (click)="onCancel()">Cancel</button>
          <button [disabled]="hotelForm.invalid || loading" igxButton igxRipple (click)="onSave()">Save</button>
          <button igxButton="icon" igxRipple igxRippleCentered="true" (click)="dialog.open()">
              <igx-icon>delete</igx-icon>
          </button>
      </igx-card-actions>
  </igx-card>

  <igx-dialog #dialog title="Confirmation"
      leftButtonLabel="Cancel"
      (onLeftButtonSelect)="dialog.close()"
      rightButtonLabel="OK"
      (onRightButtonSelect)="deleteHotel(hotelCode.value); dialog.close()">
      <div class="p-2 delete-dialog">
          <p>Please note that this action will also delete all the functionalities, categories, items, devices and orders related to this hotel.</p>
          <p>Please type in the hotel <strong>code</strong> to confirm.</p>
          <igx-input-group>
              <label igxLabel>Code</label>
              <input igxInput #hotelCode name="code" />
          </igx-input-group>
      </div>
  </igx-dialog>

  <igx-toast #toast></igx-toast>

</div>

<igx-linear-bar class="loading" *ngIf="loading" indeterminate="true"></igx-linear-bar>
