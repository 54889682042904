import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { IgxPaginatorComponent } from 'igniteui-angular';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { PreviewCategory } from '../../classes/preview-category';
import { ItemService } from '../../services/item.service';
import { Hotel } from '../../classes/hotel';
import { Router } from '@angular/router';
import { PreviewItem } from '../../classes/preview-item';

@Component({
  selector: 'app-view-items',
  templateUrl: './view-items.component.html',
  styleUrls: ['./view-items.component.scss']
})
export class ViewItemsComponent implements OnInit, OnDestroy {

  @Input()
  public hotel: Hotel;

  @ViewChild("paginator", { static: true })
  public paginator: IgxPaginatorComponent;
  public page: number = 0;
  public total: number = 100;
  public selectOptions: number[] = [20, 50, 100];
  public sizePerPage: number = 20;

  public items: PreviewItem[] = [];
  public selectedCategory: PreviewCategory;

  private refreshRef: any;

  public constructor(
    private itemService: ItemService,
    private router: Router
  ) { }

  public ngOnInit(): void {
    if (!this.hotel) return
    this.paginator.pageChange
      .subscribe(
        (page: number) => {
          this.page = page;
          this.getItems();
        }
      )
    this.paginator.perPageChange
      .subscribe(
        _ => {
          this.paginator.page = 0;
        }
      )
    const id = localStorage.getItem('category');
    let categories = [];
    this.hotel.functionalities.forEach(f => {
      categories = categories.concat(f.categories);
    });
    this.selectedCategory = categories.filter(category => category.id == Number.parseInt(id))[0];
    if (!this.selectedCategory) {
      this.selectedCategory = categories[0];
    }
    if (this.selectedCategory) {
      this.onSelectCategory(this.selectedCategory);
    }
    this.registerAutoRefresh();
  }

  public ngOnDestroy(): void {
    clearInterval(this.refreshRef)
  }

  public registerAutoRefresh(): void {
    this.refreshRef = setInterval(() => {
      this.paginator.pageChange.emit(this.page);
    }, 10000);
  }

  public dropItem(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    this.itemService
      .updateSequence(this.items)
      .subscribe(
        res => { }
      );
  }

  public onSelectCategory(category: PreviewCategory): void {
    this.selectedCategory = category;
    localStorage.setItem('category', category.id.toString());
    this.paginator.page = 0;
  }

  public getItems(): void {
    if (this.selectedCategory) {
      this.itemService
        .findAllByCategoryAndPage(this.selectedCategory.id, this.page, this.sizePerPage)
        .subscribe(
          res => {
            this.items = res.items;
            this.total = res.total;
          }
        );
    }
  }

  public editItem(item: PreviewItem): void {
    this.router.navigate([`${this.router.url}/item/edit/${item.id}`]);
  }

  public viewItem(item: PreviewItem): void {
    this.router.navigate([`${this.router.url}/item/${item.id}`]);
  }

}
