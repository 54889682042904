import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable()
export class AppGuard implements CanActivate {

  public constructor(
    private router: Router,
    private userService: UserService
  ) { }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
      const isLogged = this.userService.isLogged();
      const canActivate = isLogged;
      if (!canActivate) {
        this.router.navigate(['/login'], { replaceUrl: true });
      }
      return canActivate;
  }

}
