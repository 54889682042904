<div class="wrapper">

    <igx-card>
        <igx-card-header>
            <h3 igxCardHeaderTitle>Update credentials</h3>
        </igx-card-header>

        <igx-card-content>
            <form #credentialsForm="ngForm">
                <igx-input-group>
                    <label igxLabel>Email</label>
                    <input igxInput required type="email" [(ngModel)]="request.email" name="email"/>
                    <igx-suffix>
                        <button igxButton="icon">
                            <igx-icon fontSet="material">email</igx-icon>
                        </button>
                    </igx-suffix>
                </igx-input-group>
                <igx-input-group>
                    <label igxLabel>Old password</label>
                    <input igxInput required [type]="oldPasswordVisibility ? 'text' : 'password'"
                        [(ngModel)]="request.oldPassword" name="oldPassword" />
                    <igx-suffix>
                        <button igxButton="icon" (click)="oldPasswordVisibility = !oldPasswordVisibility">
                            <igx-icon fontSet="material">visibility</igx-icon>
                        </button>
                    </igx-suffix>
                </igx-input-group>
                <igx-input-group>
                    <label igxLabel>New password</label>
                    <input igxInput required [type]="newPasswordVisibility ? 'text' : 'password'"
                        [(ngModel)]="request.newPassword" name="newPassword" />
                    <igx-suffix>
                        <button igxButton="icon" (click)="newPasswordVisibility = !newPasswordVisibility">
                            <igx-icon fontSet="material">visibility</igx-icon>
                        </button>
                    </igx-suffix>
                </igx-input-group>
            </form>
        </igx-card-content>

        <igx-card-actions>
            <button [disabled]="credentialsForm.invalid || loading" igxButton igxRipple (click)="onSave()">Save</button>
        </igx-card-actions>
    </igx-card>
    <igx-toast #toast></igx-toast>
</div>

<igx-linear-bar class="loading" *ngIf="loading" indeterminate="true"></igx-linear-bar>
