<div class="main" igxLayout>
    <igx-nav-drawer *ngIf="isLoggedIn"
        id="navigation"
        enableGestures="true">
        <ng-template igxDrawer>
            <nav>
                <span igxDrawerItem [isHeader]="true">Room Service - Admin Panel</span>

                <span *ngFor="let item of items"
                  [routerLink]="item.link"
                  igxDrawerItem
                  [active]="item.active" igxRipple>
                    <igx-icon fontSet="material">{{ item.name }}</igx-icon>
                    <span>{{ item.text }}</span>
                </span>
                <span igxDrawerItem (click)="logout()">
                    <igx-icon fontSet="material">exit_to_app</igx-icon>
                    <span>Logout</span>
                </span>
            </nav>
        </ng-template>
    </igx-nav-drawer>

    <main>
        <span id="menu" *ngIf="isLoggedIn" igxButton="icon" igxToggleAction="navigation">
            <igx-icon fontSet="material">menu</igx-icon>
        </span>
        <router-outlet></router-outlet>
    </main>
</div>
