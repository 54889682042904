import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HotelComponent } from './hotel.component';
import { ViewHotelComponent } from './components/view-hotel/view-hotel.component';
import { EditHotelComponent } from './components/edit-hotel/edit-hotel.component';
import { CreateHotelComponent } from './components/create-hotel/create-hotel.component';
import { CreateCategoryComponent } from './components/create-category/create-category.component';
import { CreateItemComponent } from './components/create-item/create-item.component';
import { EditCategoryComponent } from './components/edit-category/edit-category.component';
import { EditItemComponent } from './components/edit-item/edit-item.component';
import { ViewItemComponent } from './components/view-item/view-item.component';
import { ViewDeviceComponent } from './components/view-device/view-device.component';
import { ViewOrderComponent } from './components/view-order/view-order.component';
import { OrderHistoryComponent } from './components/order-history/order-history.component';

const routes: Routes = [
  { path: '', component: HotelComponent },
  { path: 'create', component: CreateHotelComponent },
  { path: ':id', component: ViewHotelComponent },
  { path: 'edit/:id', component: EditHotelComponent },

  { path: ':hotelId/category/new', component: CreateCategoryComponent },
  { path: ':hotelId/category/edit/:id', component: EditCategoryComponent },
  { path: ':hotelId/category/:id', component: EditCategoryComponent },

  { path: ':hotelId/item/new', component: CreateItemComponent },
  { path: ':hotelId/item/edit/:id',  component: EditItemComponent },
  { path: ':hotelId/item/:id', component: ViewItemComponent },

  { path: ':hotelId/device/:id', component: ViewDeviceComponent },
  { path: ':hotelId/order/history', component: OrderHistoryComponent },
  { path: ':hotelId/order/:id', component: ViewOrderComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HotelRoutingModule { }
