import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { Hotel } from '../classes/hotel';
import { CreateHotelRequest } from '../classes/create-hotel';
import { EditHotelRequest } from '../classes/edit-hotel';
import { PreviewHotel } from '../classes/preview-hotel';

@Injectable()
export class HotelService {

  public constructor(
    private apiService: ApiService,
    private userService: UserService
  ) { }

  public findAll(): Observable<PreviewHotel[]> {
    const token = this.userService.get() && this.userService.get().token || '';
    return this.apiService.get('/hotels', token);
  }

  public findById(id: number): Observable<Hotel> {
    const token = this.userService.get() && this.userService.get().token || '';
    return this.apiService.get(`/hotels/${id}`, token);
  }

  public create(createHotelRequest: CreateHotelRequest): Observable<any> {
    let hotel = Object.assign(new CreateHotelRequest(), createHotelRequest);
    hotel = this.prepareHotelForSending(hotel);
    const token = this.userService.get() && this.userService.get().token || '';
    return this.apiService.post('/hotels', hotel, token);
  }

  public edit(editHotelRequest: EditHotelRequest, id: number): Observable<any> {
    let hotel = Object.assign(new EditHotelRequest(), editHotelRequest);
    hotel = this.prepareHotelForSending(hotel);
    const token = this.userService.get() && this.userService.get().token || '';
    return this.apiService.put(`/hotels/${id}`, hotel, token);
  }

  public delete(hotelId: number): Observable<any> {
    const token = this.userService.get() && this.userService.get().token || '';
    return this.apiService.delete(`/hotels/${hotelId}`, token);
  }

  public uploadApk(request: any): Observable<any> {
    const token = this.userService.get() && this.userService.get().token || '';
    return this.apiService.post('/hotels/apk', request ,token);
  }

  private prepareHotelForSending(hotel: any): any {
    hotel.serviceAvailableFrom = this.formatHour(hotel.serviceAvailableFrom);
    hotel.serviceAvailableTo = this.formatHour(hotel.serviceAvailableTo);
    hotel.checkIn = this.formatHour(hotel.checkIn);
    hotel.checkOut = this.formatHour(hotel.checkOut);
    hotel.dayChange = this.formatHour(hotel.dayChange);
    return hotel;
  }

  private formatHour(date: Date): string {
    const minutes =  date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;
    const hours =  date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`;
    return `${hours}:${minutes}`;
  }
}
