import { Component, OnInit, ViewChild } from '@angular/core';
import { IgxToastComponent } from 'igniteui-angular';
import { HotelService } from '../../services/hotel.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { CategoryLanguageContent } from 'src/app/hotel/classes/category-language-content';
import { EditCategoryRequest } from '../../classes/edit-category';
import { FunctionalityService } from '../../services/functionality.service';
import { CategoryService } from 'src/app/hotel/services/category.service';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.scss']
})
export class EditCategoryComponent implements OnInit {

  @ViewChild(IgxToastComponent, { static: true })
  public toast: IgxToastComponent;

  public editCategory: EditCategoryRequest = new EditCategoryRequest();
  public loading = false;
  public hotels = [];
  public functionalities = [];
  public category: any;
  public selectedHotel: any;

  public constructor(
    private functionalityService: FunctionalityService,
    private categoryService: CategoryService,
    private hotelService: HotelService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
  ) { }

  public ngOnInit(): void {
    this.activatedRoute
      .params
      .subscribe(
        params => {
          const id = params.id;
          this.categoryService
            .findById(id)
            .subscribe(
              category => {
                this.hotelService
                  .findAll()
                  .subscribe(
                    hotels => {
                      this.hotels = hotels;
                      this.selectedHotel = this.hotels.filter(h => h.id === category.hotelId)[0];
                      this.functionalityService
                        .findAllByHotel(this.selectedHotel.id)
                        .subscribe(
                          data => {
                            this.category = category;
                            this.functionalities = data;
                            this.editCategory.functionalityId = category.functionalityId;
                            this.editCategory.name = category.name;
                            const languageContentList: {content: string, field: string, languageCode: string}[] = category.languageContentList;
                            const languageContent: CategoryLanguageContent = new CategoryLanguageContent();
                            languageContent.nameEn = languageContentList.filter(item => item.languageCode === 'EN' && item.field === 'name')[0].content;
                            languageContent.nameFr = languageContentList.filter(item => item.languageCode === 'FR' && item.field === 'name')[0].content;
                            languageContent.nameIt = languageContentList.filter(item => item.languageCode === 'IT' && item.field === 'name')[0].content;
                            this.editCategory.languageContent = languageContent;
                          }
                        );
                    }
                  );
              }
            );
        }
      );
  }

  public onChange(event: any): void {
    const hotel = event.newSelection.value;
    this.functionalityService
    .findAllByHotel(hotel.id)
    .subscribe(
      data => {
        this.functionalities = data;
      }
    );
  }

  public onSave(): void {
    this.loading = true;
    this.categoryService
      .update(this.editCategory, this.category.id)
      .subscribe(
        res => {
          this.loading = false;
          this.location.back();
        },
        err => {
          this.loading = false;
          this.toast.message = err.error.message;
          this.toast.show();
        }
      );
  }

  public onCancel(): void {
    this.location.back();
  }

}
