<div class="wrapper">
  <igx-card *ngIf="device">
      <igx-card-header>
          <h3 igxCardHeaderTitle>Room: {{device.room}}</h3>
          <h5 igxCardHeaderSubtitle>Code: {{device.code}}</h5>
          <h5 igxCardHeaderSubtitle>Installed version: {{device.appVersion}}</h5>
          <igx-chip>
              <igx-icon igxPrefix>room_service</igx-icon>
              {{device.nrOfOrders}} orders
          </igx-chip>
      </igx-card-header>

      <igx-card-content>
          <p>Status: {{device.status}}</p>
          <p>Role: {{device.role}}</p>
      </igx-card-content>

      <igx-divider></igx-divider>

      <igx-card-actions>
          <igx-switch [checked]="device.active" (change)="changeStatus(device)">
              Active
          </igx-switch>
          <button igxButton="icon" igxRipple igxRippleCentered="true" (click)="delete.open()">
              <igx-icon>delete</igx-icon>
          </button>
      </igx-card-actions>
  </igx-card>

  <igx-tabs class="mt-2" *ngIf="device">
      <igx-tabs-group label="Orders" icon="room_service">
          <app-view-orders [hotelId]="hotelId" [deviceId]="device.id"></app-view-orders>
      </igx-tabs-group>
  </igx-tabs>

</div>

<igx-toast #toast></igx-toast>

<igx-dialog #delete title="Confirmation"
  leftButtonLabel="Cancel"
  (onLeftButtonSelect)="delete.close()"
  rightButtonLabel="OK"
  (onRightButtonSelect)="deleteDevice(); delete.close()"
  message="Are you sure you want to delete this device?">
</igx-dialog>

<igx-linear-bar class="loading" *ngIf="loading" indeterminate="true"></igx-linear-bar>
