import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { PreviewOrder } from '../classes/preview-order';

@Injectable()
export class PdfService {

    public constructor(
        private apiService: ApiService,
        private userService: UserService
    ) { }

    public generateSalesPdf(
        hotelId: number,
        hotelName: string,
        filterStartDate: string,
        filterEndDate: string,
        filterRoom: string,
        orders: PreviewOrder[]
    ): Observable<Blob> {
        let sales = [];
        let nrOfNfOrders = 0, nrOfRsOrders = 0, nrOfOrders = 0, total = 0;
        orders.forEach(order => {
            nrOfOrders++;
            if (order.feeAmount > 0) {
                nrOfRsOrders++;
            } else {
                nrOfNfOrders++;
            }
            total += order.total;
            const date = new Date(order.completedOn);
            const day = date.getDate() < 10 ?
                `0${date.getDate()}` :
                date.getDate();
            const month = date.getMonth() + 1 < 10 ?
                `0${date.getMonth() + 1}` :
                date.getMonth() + 1;
            const year = date.getFullYear() < 10 ?
                `0${date.getFullYear()}` :
                date.getFullYear();
            sales.push({
                name: order.name,
                date: `${day}.${month}.${year}`,
                room: order.device.room,
                total: `€ ${order.total.toFixed(2)}`
            });
        });
        const request = {
            type: 'SALES',
            hotelId: hotelId,
            salePayload: {
                total: `€ ${total.toFixed(2)}`,
                nrOfNfOrders: nrOfNfOrders,
                nrOfRsOrders: nrOfRsOrders,
                nrOfOrders: nrOfOrders,
                filterRoom: filterRoom,
                filterEndDate: filterEndDate,
                filterStartDate: filterStartDate,
                hotel: hotelName,
                sales: sales
            }
        };
        const token = this.userService.get() && this.userService.get().token || '';
        return this.apiService.postBlob('/pdf/download', request, token);
    }

}
