import { Injectable } from '@angular/core';

@Injectable()
export class UserService {

  private readonly USER_FLAG = 'room.service.user.flag';

  public constructor() { }

  public save(user: { token: string, id: number, email: string }): void {
    localStorage.setItem(this.USER_FLAG, JSON.stringify(user));
  }

  public get(): { id: number, token: string, email: string } {
    const user = localStorage.getItem(this.USER_FLAG);
    return JSON.parse(user) || null;
  }

  public isLogged(): boolean {
    return this.get() !== null;
  }

  public logout(): void {
    localStorage.clear();
  }

}
