<div class="wrapper">
  <igx-card *ngIf="item">
      <igx-card-header>
          <h3 igxCardHeaderTitle>{{item.name}}</h3>
          <h5 igxCardHeaderSubtitle>{{item.type}}</h5>
          <igx-chip>
              <igx-icon igxPrefix>room_service</igx-icon>
              {{item.nrOfOrders}} orders
          </igx-chip>
      </igx-card-header>

      <igx-card-content>
          <p>{{item.shortDescription}}</p>
          <igx-divider></igx-divider>
          <p>{{item.longDescription}}</p>

          <span class="mt-1 category">Sales</span>
          <div igxLayout igxLayoutItemAlign="center">
              <p style="margin: 0;">
                  Price: € <strong>{{item.price.toFixed(2)}}</strong>
              </p>
              <igx-chip class="ml-1">
                  <igx-icon igxPrefix>attach_money</igx-icon>
                  {{item.hasFee ? 'With' : 'Without'}} service fee
              </igx-chip>
              <igx-chip class="ml-1">
                  <igx-icon igxPrefix>shopping_bask</igx-icon>
                  {{item.isForSale ? 'Is' : 'Is not'}} for sale
              </igx-chip>
              <igx-chip class="ml-1">
                <igx-icon igxPrefix>attach_money</igx-icon>
                {{item.allowPreOrder ? 'Allowed for' : 'Not allowed for'}} Pre Order
              </igx-chip>
          </div>

          <span class="mt-1 category">Inventory reminders</span>
          <div igxLayout igxLayoutDir="column">
              <span>Quantity left in stock: <strong>{{item.stockQuantityLimitWarning}}</strong></span>
              <span>Days before expiration: <strong>{{item.expirationPeriodLimitWarning}}</strong></span>
          </div>

          <span class="mt-1 category">Availability</span>
          <div>
              <span>Quantity: {{item.quantity}}</span>
          </div>
          <div>
              <span>Expires on: {{item.expireOn}}</span>
          </div>
      </igx-card-content>

      <igx-divider></igx-divider>

      <igx-card-actions>
          <button igxButton igxRipple (click)="editItem()">Edit</button>
          <button igxButton="icon" igxRipple igxRippleCentered="true" (click)="copyDialog.open()">
              <igx-icon>file_copy</igx-icon>
          </button>
          <button igxButton="icon" igxRipple igxRippleCentered="true" (click)="deleteDialog.open()">
              <igx-icon>delete</igx-icon>
          </button>
      </igx-card-actions>
  </igx-card>

  <igx-tabs class="mt-2" *ngIf="item && selectedHotel">
    <igx-tabs-group label="Orders" icon="room_service">
        <app-view-orders [hotelId]="selectedHotel.id" [itemId]="item.id" ></app-view-orders>
    </igx-tabs-group>
    <igx-tabs-group label="Availability rules" icon="view_list">
        <app-view-availabilities [item]="item"></app-view-availabilities>
    </igx-tabs-group>
</igx-tabs>

  <igx-dialog #copyDialog title="Copy item to"
      leftButtonLabel="Cancel"
      (onLeftButtonSelect)="copyDialog.close()"
      rightButtonLabel="Copy"
      (onRightButtonSelect)="copyItem(); copyDialog.close()">
      <div class="p-2">
          <igx-select [(ngModel)]="selectedHotel">
              <label igxLabel>Hotel</label>
              <igx-select-item *ngFor="let hotel of hotels" [value]="hotel">
                  {{hotel.name}}
              </igx-select-item>
          </igx-select>

          <igx-select [(ngModel)]="selectedCategory" *ngIf="selectedHotel">
              <label igxLabel>Category</label>
              <igx-select-item-group *ngFor="let functionality of selectedHotel.functionalities" [label]="functionality.name">
                  <igx-select-item *ngFor="let category of functionality.categories" [value]="category">
                      {{category.name}}
                  </igx-select-item>
              </igx-select-item-group>
          </igx-select>
      </div>
  </igx-dialog>

  <igx-dialog #deleteDialog title="Confirmation"
      leftButtonLabel="Cancel"
      (onLeftButtonSelect)="deleteDialog.close()"
      rightButtonLabel="OK"
      (onRightButtonSelect)="deleteItem(); deleteDialog.close()"
      message="Are you sure you want to delete this item?">
  </igx-dialog>

  <igx-toast #toast></igx-toast>

</div>
<igx-linear-bar class="loading" *ngIf="loading" indeterminate="true"></igx-linear-bar>
