export class CreateHotelRequest {
    name: string;
    code: string;
    languageId: number;
    feeAmount: number;
    serviceAvailableFrom: Date;
    serviceAvailableTo: Date;
    checkIn: Date;
    checkOut: Date;
    adminId: number;
    stockQuantityLimitWarning: number;
    expirationPeriodLimitWarning: number;
    unlockCode: string;
    dayChange: Date;

    constructor() {
        this.name = '';
        this.code = '';
        this.languageId = null;
        this.feeAmount = 0;
        const now = new Date(Date.now());
        this.serviceAvailableFrom = now;
        this.serviceAvailableTo = now;
        this.checkIn = now;
        this.checkOut = now;
        this.expirationPeriodLimitWarning = 10;
        this.stockQuantityLimitWarning = 10;
        this.unlockCode = '';
        this.dayChange = now;
    }
}
