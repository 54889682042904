import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { UserService } from './shared/services/user.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public isLoggedIn = false;
  public items = [
    { name: 'hotel', text: 'Hotels', active: true, link: '/hotel' },
    { name: 'settings', text:'Settings', active: false, link: '/settings' }
  ];

  public constructor(
    private router: Router,
    private userService: UserService
  ) { }

  public ngOnInit(): void {
    this.router
      .events
      .subscribe(
        event => {
          if (event instanceof NavigationEnd) {
            this.items.forEach(item => { item.active = event.url.includes(item.link) });
            this.isLoggedIn = !event.url.includes('/login') && this.userService.isLogged();
          }
        }
      );
  }

  public logout(): void {
    this.userService.logout();
    this.router.navigate(['/login'], { replaceUrl: true });
  }

}
