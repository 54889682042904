<div class="wrapper">

  <igx-input-group type="search">
    <igx-prefix>
      <igx-icon>search</igx-icon>
    </igx-prefix>
    <input #search igxInput placeholder="Search Hotels" [(ngModel)]="searchHotel">
    <igx-suffix *ngIf="search.value.length > 0" (click)="searchHotel = null">
      <igx-icon>clear</igx-icon>
    </igx-suffix>
  </igx-input-group>

  <igx-list #hotelList class="mt-1" displayDensity="comfortable">
    <igx-list-item isHeader="true">Hotels</igx-list-item>
    <igx-list-item igxRipple igxRippleTarget=".igx-list__item-content"
        #item (click)="viewHotel(hotel)"
        *ngFor="let hotel of hotels | igxFilter: filterHotels;">
      <span igxListLineTitle>{{ hotel.name }}</span>
      <span igxListLineSubTitle>{{ hotel.code }}</span>
      <igx-icon igxListAction [igxTooltipTarget]="tooltipRef" (click)="editHotel(hotel)">edit</igx-icon>
    </igx-list-item>
    <div #tooltipRef="tooltip" igxTooltip>
        Edit
    </div>
    <ng-template igxEmptyList>
      <p class="center">No hotel found. Try the add button.</p>
    </ng-template>
    <ng-template igxDataLoading>
      <p class="center">Patience, we are currently loading your data...</p>
    </ng-template>
  </igx-list>

  <button igxButton igxRipple (click)="addHotel()">
    Add hotel
    <igx-icon fontSet="material">add</igx-icon>
  </button>

</div>
