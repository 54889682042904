<div class="wrapper">
  <igx-card *ngIf="order">
      <igx-card-header>
          <h3 igxCardHeaderTitle>Order: {{order.name}}</h3>
          <h5 igxCardHeaderSubtitle>Room: {{order.device.room}}</h5>
          <igx-chip>{{order.status}}</igx-chip>
      </igx-card-header>

      <igx-card-content>
          <p>Notes: {{order.notes}}</p>
      </igx-card-content>
      <igx-divider></igx-divider>
      <igx-card-content igxLayout igxLayoutDir="row" igxLayoutJustify="space-between">
          <div>
              <span>Fee amount: € {{order.feeAmount.toFixed(2)}}</span><br/>
              <span>Total: € {{order.total.toFixed(2)}}</span>
          </div>
          <div>
              <span *ngIf="createdOn">Created on: {{createdOn}}</span><br/>
              <span *ngIf="confirmedOn">Confirmed on: {{confirmedOn}}</span><br/>
              <span *ngIf="completedOn">Completed on: {{completedOn}}</span><br/>
          </div>
      </igx-card-content>
  </igx-card>

  <igx-list class="mt-1" *ngIf="order">
      <igx-list-item isHeader="true">Order items</igx-list-item>
      <ng-template igxEmptyList>
          <p class="center">No items linked with this order.</p>
      </ng-template>
      <ng-template igxDataLoading>
          <p class="center">Patience, we are currently loading your data...</p>
      </ng-template>
      <igx-list-item *ngFor="let item of order.items">
          <span igxListLineTitle>Item: {{item.item.name}}</span>
          <span igxListLineSubTitle>Quantity: {{item.quantity}}</span>
          <span igxListLineSubTitle>Price: € {{item.item.price.toFixed(2)}}</span>
      </igx-list-item>
  </igx-list>

</div>

<igx-linear-bar class="loading" *ngIf="loading" indeterminate="true"></igx-linear-bar>
