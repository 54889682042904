import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { PreviewLanguage } from '../classes/preview-language';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  public constructor(
    private apiService: ApiService,
    private userService: UserService
  ) { }

  public findAll(): Observable<PreviewLanguage[]> {
    const token = this.userService.get() && this.userService.get().token || '';
    return this.apiService.get('/languages', token);
  }
}
