import { Component, OnInit } from '@angular/core';
import { OrderService } from '../../services/order.service';
import { ActivatedRoute } from '@angular/router';
import { Order } from '../../classes/order';

@Component({
  selector: 'app-view-order',
  templateUrl: './view-order.component.html',
  styleUrls: ['./view-order.component.scss']
})
export class ViewOrderComponent implements OnInit {

  public order: Order;
  public loading = true;
  public completedOn: string;
  public confirmedOn: string;
  public createdOn: string;

  public constructor(
    private orderService: OrderService,
    private route: ActivatedRoute
  ) { }

  public ngOnInit(): void {
    this.route.params
      .subscribe(
        params => {
          const id = params.id;
          this.orderService
            .findById(id)
            .subscribe(
              order => {
                this.order = order;
                this.loading = false;
                if (order.completedOn) {
                  this.completedOn = new Date(order.completedOn.concat("+00:00")).toLocaleString();
                }
                if (order.confirmedOn) {
                  this.confirmedOn = new Date(order.confirmedOn.concat("+00:00")).toLocaleString();
                }
                if (order.createdOn) {
                  this.createdOn = new Date(order.createdOn.concat("+00:00")).toLocaleString();
                }
              }
            );
        }
      );
  }

}
