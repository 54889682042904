export class ItemLanguageContent {
    nameEn: string;
    nameFr: string;
    nameIt: string;
    shortDescriptionEn: string;
    shortDescriptionFr: string;
    shortDescriptionIt: string;
    longDescriptionEn: string;
    longDescriptionFr: string;
    longDescriptionIt: string;

    constructor() {
        this.nameEn = '';
        this.nameFr = '';
        this.nameIt = '';
        this.shortDescriptionEn = '';
        this.shortDescriptionFr = '';
        this.shortDescriptionIt = '';
        this.longDescriptionEn = '';
        this.longDescriptionFr = '';
        this.longDescriptionIt = '';
    }
}