<div class="wrapper">

  <div igxLayout igxLayoutItemAlign="flex-end">
      <igx-date-picker igxFlex [(ngModel)]="startFilterDate" name="from">
          <ng-template igxDatePickerTemplate let-openDialog="openDialog" let-value="value" let-displayData="displayData">
              <igx-input-group (click)="openDialog(from)">
                  <igx-prefix>
                      <igx-icon>today</igx-icon>
                  </igx-prefix>
                  <label igxLabel>From</label>
                  <input #from igxInput [value]="displayData"/>
              </igx-input-group>
          </ng-template>
      </igx-date-picker>
      <igx-date-picker igxFlex [(ngModel)]="endFilterDate" name="from">
          <ng-template igxDatePickerTemplate let-openDialog="openDialog" let-value="value" let-displayData="displayData">
              <igx-input-group (click)="openDialog(to)">
                  <igx-prefix>
                      <igx-icon>today</igx-icon>
                  </igx-prefix>
                  <label igxLabel>To</label>
                  <input #to igxInput [value]="displayData"/>
              </igx-input-group>
          </ng-template>
      </igx-date-picker>
      <igx-select igxFlex [(ngModel)]="selectedStatus">
          <label igxLabel>Status</label>
          <igx-select-item *ngFor="let status of statuses" [value]="status.name">
              {{status.value}}
          </igx-select-item>
      </igx-select>
      <igx-select igxFlex [(ngModel)]="selectedRoom" #room>
          <label igxLabel>Room</label>
          <igx-select-item *ngFor="let device of devices" [value]="device">
              {{device.room}}
          </igx-select-item>
          <igx-suffix>
              <button igxButton="icon" (click)="room.clearSelection(); selectedRoom = null">
                  <igx-icon>clear</igx-icon>
              </button>
          </igx-suffix>
      </igx-select>
      <button igxButton igxRipple (click)="filter()">Filter</button>
      <button *ngIf="printable" igxButton igxRipple (click)="print()">Print</button>
  </div>

  <igx-list class="mt-1">
      <igx-list-item [isHeader]="true">
        <span style="font-size: 12px;">Orders: {{nrOfOrders}}</span>
        <span style="font-size: 12px; margin-left: 15px;">RS: {{rsOrders}}</span>
        <span style="font-size: 12px; margin-left: 15px;">NF: {{nfOrders}}</span>
        <span style="font-size: 12px; margin-left: 15px;">Total: €{{total}}</span> 
      </igx-list-item>
      <ng-template igxDataLoading>
          <p class="center">Patience, we are currently loading your data...</p>
      </ng-template>
      <igx-list-item
          (click)="viewOrder(order)"
          *ngFor="let order of orders">
          <span igxListLineTitle>Order: {{order.name}}</span>
          <span igxListLineSubTitle>Total: € {{order.total.toFixed(2)}}</span>
      </igx-list-item>
  </igx-list>

</div>

<igx-linear-bar class="loading" *ngIf="loading" indeterminate="true"></igx-linear-bar>
