import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from 'src/app/shared/services/user.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { PreviewDevice } from '../classes/preview-device';
import { Device } from '../classes/device';
import { DevicesResponse } from '../classes/devices.response';

@Injectable()
export class DeviceService {

  public constructor(
    private apiService: ApiService,
    private userService: UserService
  ) { }

  public findAllByHotel(hotelId: number): Observable<PreviewDevice[]> {
    const token = this.userService.get() && this.userService.get().token || '';
    return this.apiService
      .get(`/devices/hotel/${hotelId}`, token)
      .pipe(
        map((devices: PreviewDevice[]) => {
          devices.forEach(device => device.active = device.status === 'ACTIVE');
          return devices;
        })
      );
  }

  public findAllByHotelAndPage(hotelId: number, page: number, size: number): Observable<DevicesResponse> {
    const token = this.userService.get() && this.userService.get().token || '';
    return this.apiService
      .get(`/devices?hotelId=${hotelId}&page=${page}&size=${size}`, token)
      .pipe(
        map((r: DevicesResponse) => {
          r.devices.forEach(device => device.active = device.status === 'ACTIVE');
          return r;
        })
      );
  }

  public findAllReceptions(hotelId: number): Observable<PreviewDevice[]> {
    const token = this.userService.get() && this.userService.get().token || '';
    return this.apiService
      .post(`/devices/filter`, {hotelId, role: 'RECEPTION', status: 'ACTIVE'}, token);
  }

  public findAllDepartments(hotelId: number): Observable<PreviewDevice[]> {
    const token = this.userService.get() && this.userService.get().token || '';
    return this.apiService
      .post(`/devices/filter`, {hotelId, role: 'DEPARTMENT', status: 'ACTIVE'}, token);
  }

  public findById(id: number): Observable<Device> {
    const token = this.userService.get() && this.userService.get().token || '';
    return this.apiService.get(`/devices/${id}`, token)
    .pipe(map((device: Device) => {
      device.active = device.status === 'ACTIVE';
      return device;
    }));
  }

  public executeCommand(deviceId: number, command: string): Observable<any> {
    const token = this.userService.get() && this.userService.get().token || '';
    return this.apiService.post('/devices/execute', { command, deviceId: deviceId }, token)
  }

  public delete(deviceId: number): Observable<any> {
    const token = this.userService.get() && this.userService.get().token || '';
    return this.apiService.delete(`/devices/${deviceId}`, token);
  }
}
