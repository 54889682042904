import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginGuard } from './shared/guards/login.guard';
import { AppGuard } from './shared/guards/app.guard';


const routes: Routes = [

  {
    path: '',
    redirectTo: '/hotel',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    canActivate: [ LoginGuard ]
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    canActivate: [ AppGuard ]
  },
  {
    path: 'hotel',
    loadChildren: () => import('./hotel/hotel.module').then(m => m.HotelModule),
    canActivate: [ AppGuard ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
