import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IgxFilterOptions } from 'igniteui-angular';
import { map } from 'rxjs/operators';
import { PdfService } from 'src/app/hotel/services/pdf.service';
import { PreviewDevice } from '../../classes/preview-device';
import { PreviewOrder } from '../../classes/preview-order';
import { DeviceService } from '../../services/device.service';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {

  public searchOrder: string;
  public orders: PreviewOrder[] = [];
  public devices: PreviewDevice[] = [];
  public selectedRoom: PreviewDevice = null;
  public selectedStatus: string = 'COMPLETED';
  public loading = false;
  public printable = false;
  public statuses = [
    { name: 'NEW', value: 'New' },
    { name: 'IN_PROGRESS', value: 'Confirmed' },
    { name: 'COMPLETED', value: 'Completed' },
    { name: 'UNCONFIRMED', value: 'Unconfirmed' },
    { name: 'CANCELLED', value: 'Cancelled' },
  ];
  public hotelId: number;
  public hotelName: string;
  public nrOfOrders: number = 0;
  public rsOrders: number = 0;
  public nfOrders: number = 0;
  public total: String = "0.00";

  public startFilterDate = new Date();
  public endFilterDate = new Date();

  public constructor(
    private orderService: OrderService,
    private deviceService: DeviceService,
    private pdfService: PdfService,
    private router: Router,
  ) { }

  public ngOnInit(): void {
    this.startFilterDate.setDate(this.endFilterDate.getDate() - 30);
    this.hotelId = Number.parseInt(localStorage.getItem('hotelId'));
    this.hotelName = localStorage.getItem('hotelName');
    this.fetchDevices();
  }

  public get filterOrders() {
    const fo = new IgxFilterOptions();
    fo.key = "name";
    fo.inputValue = this.searchOrder;
    return fo;
  }

  public filter(): void {
    this.loading = true;
    const startDate = new Date(
      this.startFilterDate.getFullYear(),
      this.startFilterDate.getMonth(),
      this.startFilterDate.getDate(),
      3, 0, 0
    );
    const endDate = new Date(
      this.endFilterDate.getFullYear(),
      this.endFilterDate.getMonth(),
      this.endFilterDate.getDate() + 1,
      3, 0, 0
    );
    const startDateOffset = startDate.getTimezoneOffset() * 60000;
    const endDateOffset = endDate.getTimezoneOffset() * 60000;
    this.orderService
      .findAllFiltered({
        hotelId: this.hotelId,
        room: this.selectedRoom && this.selectedRoom.room || null,
        confirmedOnStart: (new Date(startDate.getTime() - startDateOffset)).toISOString().slice(0, -1),
        confirmedOnEnd: (new Date(endDate.getTime() - endDateOffset)).toISOString().slice(0, -1),
        status: this.selectedStatus
      })
      .pipe(map(res => res.orders))
      .subscribe(
        orders => {
          this.orders = orders.sort((a, b) => a.createdOn > b.createdOn ? -1 : 1);
          this.nrOfOrders = orders.length;
          this.rsOrders = orders.filter(order => order.feeAmount > 0).length;
          this.nfOrders = orders.filter(order => order.feeAmount === 0).length;
          this.total = orders.map(order => order.total).reduce((a, b) => a + b, 0).toFixed(2);
          this.printable = this.selectedStatus === 'COMPLETED';
          this.loading = false;
        }
      );
  }

  public print(): void {
    this.pdfService
      .generateSalesPdf(
        this.hotelId,
        this.hotelName,
        this.startFilterDate.toLocaleDateString(),
        this.endFilterDate.toLocaleDateString(),
        this.selectedRoom && this.selectedRoom.room || 'ALL',
        this.orders
      )
      .subscribe(res => {
        const file = new Blob([res], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    })
  }

  public viewOrder(order: PreviewOrder): void {
    this.router.navigateByUrl(`/orders/${order.id}`);
  }

  private fetchDevices(): void {
    this.deviceService
      .findAllByHotel(this.hotelId)
      .subscribe(
        devices => {
          this.devices = devices;
        }
      );
  }

}
