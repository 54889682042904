<igx-list style="height: auto;" class="mt-1">
  <ng-template igxEmptyList>
      <p class="center">There are no devices linked with this hotel.</p>
  </ng-template>
  <ng-template igxDataLoading>
      <p class="center">Patience, we are currently loading your data...</p>
  </ng-template>
  <igx-list-item
      *ngFor="let device of devices">
      <span
        [style.background-color]="getBackgroundColor(device)"
        style="border-radius: 50%; width: 10px; height: 10px; position: absolute; left: 10px"></span>
      <span style="margin-left: 10px;" igxListLineTitle (click)="viewDevice(device)">{{device.room}}</span>
      <span style="margin-left: 10px;" igxListLineSubTitle>{{getDeviceLiveStatus(device)}}</span>
      <igx-switch [checked]="device.active" 
        (change)="changeStatus(device)">
      </igx-switch>
  </igx-list-item>
</igx-list>
<igx-paginator #paginator
  class="mt-1"
  [selectOptions]="selectOptions"
  [page]="0"
  [totalRecords]="total"
  [(perPage)]="sizePerPage">
</igx-paginator>
<igx-linear-bar class="loading" *ngIf="loading" indeterminate="true"></igx-linear-bar>
