import { Component, OnInit, ViewChild } from '@angular/core';
import { IgxToastComponent } from 'igniteui-angular';
import { EditItemRequest } from '../../classes/edit-item';
import { PreviewHotel } from '../../classes/preview-hotel';
import { Item } from '../../classes/item';
import { ItemService } from '../../services/item.service';
import { HotelService } from '../../services/hotel.service';
import { ActivatedRoute } from '@angular/router';
import { ItemLanguageContent } from '../../classes/item-language-content';
import { Location } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';
import { DeviceService } from '../../services/device.service';
import { PreviewDevice } from '../../classes/preview-device';
import { forkJoin } from 'rxjs';
import { ItemAvailability } from '../../classes/item.availability';

@Component({
  selector: 'app-edit-item',
  templateUrl: './edit-item.component.html',
  styleUrls: ['./edit-item.component.scss']
})
export class EditItemComponent implements OnInit {

  @ViewChild(IgxToastComponent, { static: true })
  public toast: IgxToastComponent;

  public editItem: EditItemRequest = new EditItemRequest();
  public loading = false;

  public selectedHotel: PreviewHotel = { functionalities: [], id: 0, name: '', code: ''};
  public item: Item;

  public devices: PreviewDevice[] = [];

  public constructor(
    private itemService: ItemService,
    private hotelService: HotelService,
    private deviceService: DeviceService,
    private location: Location,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
    this.route
      .params
      .subscribe(
        params => {
          const id = params.id;
          this.itemService
            .findById(id)
            .subscribe(
              item => {
                this.item = item;
                this.hotelService
                  .findAll()
                  .subscribe(
                    hotels => {
                      const hotel = hotels.filter(hotel => hotel.id === item.hotelId)[0];
                      this.onChangeHotel(hotel);
                      forkJoin([
                        this.deviceService.findAllReceptions(hotel.id),
                        this.deviceService.findAllDepartments(hotel.id),
                      ]).subscribe(devices => {
                        this.devices = devices[0].concat(devices[1]);
                        this.editItem = this.prepareEditItemRequest(item);
                      });
                    }
                  );
              }
            );
        }
      );
  }

  public ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  public onChangeHotel(hotel: PreviewHotel): void {
    this.selectedHotel = hotel;
    this.cdr.detectChanges();
  }

  public onSave(): void {
    this.loading = true;
    if (this.editItem.type === 'SERVICE') {
      this.editItem.quantity = 1;
    } else if (this.editItem.type === 'PRODUCT') {
      this.editItem.isForSale = true;
    }
    if (!this.editItem.isForSale) {
      this.editItem.allowPreOrder = false;
    }
    this.itemService
      .update(this.editItem, this.item.id)
      .subscribe(
        res => {
          this.loading = false;
          this.location.back();
        },
        err => {
          this.loading = false;
          this.showMessage(err.error.message);
        }
      );
  }

  public deleteItem(): void {
    this.itemService
      .delete(this.item.id)
      .subscribe(
        res => {
          this.showMessage('Item successfully deleted.');
          this.location.back();
        },
        err => {
          this.showMessage(err.error.message);
        }
      );
  }

  public onCancel(): void {
    this.location.back();
  }

  public trackAvailability(availability: ItemAvailability): string {
    return `${availability.availableFrom}-${availability.availableTo}`;
  }

  public addAvailabilityRow(): void {
    this.editItem.availabilities.push(
      { 
        availableFrom: new Date(Date.now()), 
        availableTo: new Date(Date.now()),
        deviceId: this.devices[0].id,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true,
      })
    this.editItem.availabilities = this.editItem.availabilities.sort(this.compare);
  }

  public removeAvailabilityRow(row: any): void {
    const index = this.editItem.availabilities.indexOf(row, 0);
    if (index > -1) {
      this.editItem.availabilities.splice(index, 1);
    }
    this.editItem.availabilities = this.editItem.availabilities.sort(this.compare);
  }

  private prepareEditItemRequest(item: Item): EditItemRequest {
    const editItem = new EditItemRequest();
    editItem.name = item.name;
    editItem.price = item.price;
    editItem.quantity = item.quantity;
    editItem.shortDescription = item.shortDescription;
    editItem.type = item.type;
    editItem.longDescription = item.longDescription;
    editItem.hasFee = item.hasFee;
    editItem.categoryId = item.categoryId;
    editItem.expireOn = new Date(item.expireOn);
    editItem.isForSale = item.isForSale;
    editItem.allowPreOrder = item.allowPreOrder;
    editItem.availabilities = [];
    item.availabilities.forEach(a => {
      editItem.availabilities.push(
        {
          availableFrom: this.parseHour(a.availableFrom),
          availableTo: this.parseHour(a.availableTo),
          deviceId: a.device.id && a.device.id || null,
          monday: a.monday,
          tuesday: a.tuesday,
          wednesday: a.wednesday,
          thursday: a.thursday,
          friday: a.friday,
          saturday: a.saturday,
          sunday: a.sunday,
        }
      );
    });
    editItem.availabilities = editItem.availabilities.sort(this.compare);
    editItem.expirationPeriodLimitWarning = item.expirationPeriodLimitWarning;
    editItem.stockQuantityLimitWarning = item.stockQuantityLimitWarning;
    const languageContentList: {content: string, field: string, languageCode: string}[] = item.languageContentList;
    const languageContent: ItemLanguageContent = new ItemLanguageContent();
    languageContent.nameEn = languageContentList.filter(i => i.languageCode === 'EN' && i.field === 'name')[0].content;
    languageContent.nameFr = languageContentList.filter(i => i.languageCode === 'FR' && i.field === 'name')[0].content;
    languageContent.nameIt = languageContentList.filter(i => i.languageCode === 'IT' && i.field === 'name')[0].content;
    languageContent.shortDescriptionEn = languageContentList.filter(i => i.languageCode === 'EN' && i.field === 'short_description')[0].content;
    languageContent.shortDescriptionFr = languageContentList.filter(i => i.languageCode === 'FR' && i.field === 'short_description')[0].content;
    languageContent.shortDescriptionIt = languageContentList.filter(i => i.languageCode === 'IT' && i.field === 'short_description')[0].content;
    languageContent.longDescriptionEn = languageContentList.filter(i => i.languageCode === 'EN' && i.field === 'long_description')[0].content;
    languageContent.longDescriptionFr = languageContentList.filter(i => i.languageCode === 'FR' && i.field === 'long_description')[0].content;
    languageContent.longDescriptionIt = languageContentList.filter(i => i.languageCode === 'IT' && i.field === 'long_description')[0].content;
    editItem.languageContent = languageContent;
    return editItem;
  }

  private parseHour(input: string): Date {
    const date = new Date(Date.now());
    const parts = input.split(/[\s:]+/);
    const hour = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);
    date.setHours(hour, minutes);
    return date;
  }

  private showMessage(message: string): void {
    this.toast.message = message;
    this.toast.show();
  }

  private compare(a: any, b: any): number {
    var time1 = parseFloat(a.availableFrom.toTimeString().substring(0, 5).replace(':','.').replace(/[^\d.-]/g, ''));
    var time2 = parseFloat(b.availableFrom.toTimeString().substring(0, 5).replace(':','.').replace(/[^\d.-]/g, ''));
    if (time1 < time2) return -1;
    if (time1 > time2) return 1;
    return 0;
  } 

}
