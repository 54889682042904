import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';

@Injectable()
export class CredentialsService {

  public constructor(
    private apiService: ApiService,
    private userService: UserService
  ) { }

  public requestUpdate(request: { email: string, oldPassword: string, newPassword: string }): Observable<any> {
    const token = this.userService.get() && this.userService.get().token || '';
    return this.apiService.post(`/users/credentials`, request, token);
  }

  public update(request: { code: string }): Observable<any> {
    const token = this.userService.get() && this.userService.get().token || '';
    return this.apiService.put(`/users/credentials`, request, token);
  }

}
