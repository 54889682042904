<div class="wrapper">

  <igx-card>
      <igx-card-header>
          <h3 igxCardHeaderTitle>Add item</h3>
          <h5 igxCardHeaderSubtitle>{{item.name}}</h5>
      </igx-card-header>

      <igx-card-content>

          <igx-select class="mb-1" [(ngModel)]="selectedHotel">
              <label igxLabel>Hotel</label>
              <igx-select-item *ngFor="let hotel of hotels" [value]="hotel">
                  {{hotel.name}}
              </igx-select-item>
          </igx-select>

          <form #itemForm="ngForm">
              <igx-select required [(ngModel)]="item.categoryId" name="categoryId">
                  <label igxLabel>Category</label>
                  <igx-select-item-group *ngFor="let functionality of selectedHotel.functionalities" [label]="functionality.name">
                      <igx-select-item *ngFor="let category of functionality.categories" [value]="category.id">
                          {{category.name}}
                      </igx-select-item>
                  </igx-select-item-group>
              </igx-select>
              <igx-select igxFlex required [(ngModel)]="item.type" name="type">
                  <label igxLabel>Type</label>
                  <igx-select-item value="SERVICE">
                      Service
                  </igx-select-item>
                  <igx-select-item value="PRODUCT">
                      Product
                  </igx-select-item>
              </igx-select>
              <div class="mt-2">
                  <span class="category">Sales</span>
              </div>
              <igx-checkbox class="m-1" [(ngModel)]="item.hasFee" name="hasFee">
                  RS Fee
              </igx-checkbox>
              <igx-checkbox class="m-1" *ngIf="item.type === 'SERVICE'"
                   [(ngModel)]="item.isForSale" name="isForSale">
                  For sale
              </igx-checkbox>
              <igx-checkbox class="m-1" *ngIf="item.isForSale" [(ngModel)]="item.allowPreOrder" 
                name="allowPreOrder">
                Pre Order
              </igx-checkbox>
              <igx-input-group *ngIf="item.type === 'PRODUCT' || item.isForSale">
                  <igx-prefix>
                      <igx-icon>euro_symbol</igx-icon>
                  </igx-prefix>
                  <label igxLabel>Price</label>
                  <input igxInput type="number" required [(ngModel)]="item.price" name="price" />
              </igx-input-group>

              <div class="mt-2">
                  <span class="category">Warning limits</span>
              </div>
              <igx-checkbox class="m-1" [(ngModel)]="item.customWarningLimits" name="customWarningLimits">
                  Custom warning limits
              </igx-checkbox>
              <div igxLayout *ngIf="item.customWarningLimits">
                  <igx-input-group igxFlex>
                      <label igxLabel>Lower limit for stock quantity</label>
                      <input igxInput required [(ngModel)]="item.stockQuantityLimitWarning" name="stockQuantityLimitWarning"/>
                  </igx-input-group>
                  <igx-input-group igxFlex>
                      <label igxLabel>Expiration period limit</label>
                      <input igxInput required [(ngModel)]="item.expirationPeriodLimitWarning" name="expirationPeriodLimitWarning"/>
                  </igx-input-group>
              </div>

              <div class="mt-2" igxLayout igxLayoutJustify="space-between">
                <span class="category">Availability</span>
                <button (click)="addAvailabilityRow()" igxRipple igxButton="icon">
                  <igx-icon>add</igx-icon>
                </button>
              </div>
              <div igxLayout>
                <igx-date-picker igxFlex [(ngModel)]="item.expireOn" name="expireOn">
                    <ng-template igxDatePickerTemplate let-openDialog="openDialog" let-value="value" let-displayData="displayData">
                        <igx-input-group (click)="openDialog(expireOn)">
                            <igx-prefix>
                                <igx-icon>today</igx-icon>
                            </igx-prefix>
                            <label igxLabel>Expires on</label>
                            <input required #expireOn igxInput [value]="displayData"/>
                        </igx-input-group>
                    </ng-template>
                </igx-date-picker>
                <igx-input-group *ngIf="item.type === 'PRODUCT'" igxFlex>
                    <label igxLabel>Quantity</label>
                    <input igxInput type="number" required [(ngModel)]="item.quantity" name="quantity" />
                </igx-input-group>
              </div>
              <igx-list class="mt-1" displayDensity="comfortable">
                <igx-list-item isHeader="true">Rules</igx-list-item>
                <igx-list-item *ngFor="let a of item.availabilities;">
                    <igx-time-picker igxFlex [(ngModel)]="a.availableFrom" format="HH:mm" [name]="'availableFrom' + trackAvailability(a)">
                        <ng-template igxTimePickerTemplate let-openDialog="openDialog" let-value="value" let-displayTime="displayTime">
                          <igx-input-group (click)="openDialog('serviceAvailableFrom' + trackAvailability(a))">
                            <igx-prefix>
                              <igx-icon>access_time</igx-icon>
                            </igx-prefix>
                            <label igxLabel>Available From</label>
                            <input [id]="'serviceAvailableFrom' + trackAvailability(a)" igxInput [value]="displayTime"/>
                          </igx-input-group>
                        </ng-template>
                    </igx-time-picker>
                    <igx-time-picker igxFlex [(ngModel)]="a.availableTo" format="HH:mm" [name]="'availableTo' + trackAvailability(a)">
                        <ng-template igxTimePickerTemplate let-openDialog="openDialog" let-value="value" let-displayTime="displayTime">
                            <igx-input-group (click)="openDialog('serviceAvailableTo' + trackAvailability(a))">
                            <igx-prefix>
                                <igx-icon>access_time</igx-icon>
                            </igx-prefix>
                            <label igxLabel>Available To</label>
                            <input [id]="'serviceAvailableTo' + trackAvailability(a)" igxInput [value]="displayTime"/>
                            </igx-input-group>
                        </ng-template>
                    </igx-time-picker>
                    <igx-select igxFlex required [(ngModel)]="a.deviceId" [name]="'device'+ a.deviceId">
                        <label igxLabel>Device</label>
                        <igx-select-item [value]="d.id" *ngFor="let d of devices">
                            {{d.room}}
                        </igx-select-item>
                    </igx-select>
                    <button class="ml-2" (click)="removeAvailabilityRow(a)" igxRipple igxButton="icon">
                        <igx-icon>remove</igx-icon>
                    </button>
                    <div class="break"></div>
                    <igx-checkbox class="m-2" 
                        [(ngModel)]="a.monday"
                        [ngModelOptions]="{standalone: true}">
                        Monday
                    </igx-checkbox>
                    <igx-checkbox class="m-2" 
                        [(ngModel)]="a.tuesday"
                        [ngModelOptions]="{standalone: true}">
                        Tuesday
                    </igx-checkbox>
                    <igx-checkbox class="m-2" 
                        [(ngModel)]="a.wednesday"
                        [ngModelOptions]="{standalone: true}">
                        Wednesday
                    </igx-checkbox>
                    <igx-checkbox class="m-2" 
                        [(ngModel)]="a.thursday"
                        [ngModelOptions]="{standalone: true}">
                        Thursday
                    </igx-checkbox>
                    <igx-checkbox class="m-2" 
                        [(ngModel)]="a.friday"
                        [ngModelOptions]="{standalone: true}">
                        Friday
                    </igx-checkbox>
                    <igx-checkbox class="m-2" 
                        [(ngModel)]="a.saturday"
                        [ngModelOptions]="{standalone: true}">
                        Saturday
                    </igx-checkbox>
                    <igx-checkbox class="m-2" 
                        [(ngModel)]="a.sunday"
                        [ngModelOptions]="{standalone: true}">
                        Sunday
                    </igx-checkbox>
                </igx-list-item>
            </igx-list>
              
              <div class="mt-2">
                  <span class="category">Multi language</span>
              </div>
              <igx-tabs>
                  <igx-tabs-group label="EN">
                      <igx-input-group>
                          <label igxLabel>English Name</label>
                          <input igxInput required [(ngModel)]="item.languageContent.nameEn" name="nameEn" />
                      </igx-input-group>
                      <igx-input-group>
                          <label igxLabel>English Short description</label>
                          <input igxInput required [(ngModel)]="item.languageContent.shortDescriptionEn" name="shortDescriptionEn" />
                      </igx-input-group>
                      <igx-input-group>
                          <label igxLabel>English Long description</label>
                          <textarea igxInput required maxlength="1000" [(ngModel)]="item.languageContent.longDescriptionEn" name="longDescriptionEn"></textarea>
                      </igx-input-group>
                  </igx-tabs-group>
                  <igx-tabs-group label="FR">
                      <igx-input-group>
                          <label igxLabel>French Name</label>
                          <input igxInput required [(ngModel)]="item.languageContent.nameFr" name="nameFr" />
                      </igx-input-group>
                      <igx-input-group>
                          <label igxLabel>French Short description</label>
                          <input igxInput required [(ngModel)]="item.languageContent.shortDescriptionFr" name="shortDescriptionFr" />
                      </igx-input-group>
                      <igx-input-group>
                          <label igxLabel>French Long description</label>
                          <textarea igxInput required maxlength="1000" [(ngModel)]="item.languageContent.longDescriptionFr" name="longDescriptionFr"></textarea>
                      </igx-input-group>
                  </igx-tabs-group>
                  <igx-tabs-group label="IT">
                      <igx-input-group>
                          <label igxLabel>Italian Name</label>
                          <input igxInput required [(ngModel)]="item.languageContent.nameIt" name="nameIt" />
                      </igx-input-group>
                      <igx-input-group>
                          <label igxLabel>Italian Short description</label>
                          <input igxInput required [(ngModel)]="item.languageContent.shortDescriptionIt" name="shortDescriptionIt" />
                      </igx-input-group>
                      <igx-input-group>
                          <label igxLabel>Italian Long description</label>
                          <textarea igxInput required maxlength="1000" [(ngModel)]="item.languageContent.longDescriptionIt" name="longDescriptionIt"></textarea>
                      </igx-input-group>
                  </igx-tabs-group>
              </igx-tabs>
          </form>
      </igx-card-content>

      <igx-card-actions>
          <button igxButton igxRipple (click)="onCancel()">Cancel</button>
          <button [disabled]="itemForm.invalid || loading || item.availabilities.length === 0"
            igxButton igxRipple (click)="onSave()">Save</button>
      </igx-card-actions>
  </igx-card>
  <igx-toast #toast></igx-toast>
</div>

<igx-linear-bar class="loading" *ngIf="loading" indeterminate="true"></igx-linear-bar>
