import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { Router } from '@angular/router';

@Injectable()
export class ApiService {

  private readonly API_URL = environment.backendUrl;

  public constructor(
    private httpClient: HttpClient,
    private userService: UserService,
    private router: Router
  ) { }

  public get<T>(
    uri: string,
    token? :string
  ): Observable<T> {
    const url = this.API_URL + uri;
    const headers = this.createHeaders(token);
    return this.httpClient
      .get<T>(url, { headers })
      .pipe(catchError(error => this.onError(error)));
  }

  public post<T>(
    uri: string,
    body: any,
    token? :string
  ): Observable<T> {
    const url = this.API_URL + uri;
    const headers = this.createHeaders(token);
    return this.httpClient
      .post<T>(url, body, { headers })
      .pipe(catchError(error => this.onError(error)));
  }

  public postBlob(
    uri: string,
    body: any,
    token?: string
  ): Observable<Blob> {
    const url = this.API_URL + uri;
    const headers = this.createHeaders(token);
    return this.httpClient
      .post(url, body, { headers, responseType: 'blob' })
      .pipe(catchError(error => this.onError(error)));
  }

  public put<T>(
    uri: string,
    body: any,
    token?: string
  ): Observable<T> {
    const url = this.API_URL + uri;
    const headers = this.createHeaders(token);
    return this.httpClient
      .put<T>(url, body, { headers })
      .pipe(catchError(error => this.onError(error)));
  }

  public delete<T>(
    uri: string,
    token?: string
  ): Observable<T> {
    const url = this.API_URL + uri;
    const headers = this.createHeaders(token);
    return this.httpClient
      .delete<T>(url, { headers })
      .pipe(catchError(error => this.onError(error)));
  }

  private onError(error: HttpErrorResponse): Observable<never> {
    if (error.status === 401 ||
      error.status === 403) {
      this.userService.logout();
      this.router.navigateByUrl('/login', { replaceUrl: true });
    }
    return throwError(error);
  }

  private createHeaders(token?: string): {[header: string]: string | string[]} {
    const headers = { 'Content-Type': 'application/json' };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    return headers;
  }

}
