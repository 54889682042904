import { ItemLanguageContent } from './item-language-content';

export class EditItemRequest {
    name: string;
    shortDescription: string;
    longDescription: string;
    price: number;
    type: string;
    expireOn: Date;
    hasFee: boolean;
    isForSale: boolean;
    allowPreOrder: boolean;
    quantity: number;
    categoryId: number;
    languageContent: ItemLanguageContent;
    stockQuantityLimitWarning: number;
    expirationPeriodLimitWarning: number;
    availabilities: { 
        availableFrom: Date; 
        availableTo: Date;
        deviceId: number;
        monday: boolean;
        tuesday: boolean;
        wednesday: boolean;
        thursday: boolean;
        friday: boolean;
        saturday: boolean;
        sunday: boolean;
    }[];

    constructor() {
        this.name = '';
        this.shortDescription = '';
        this.longDescription = '';
        this.price = 0;
        this.type = 'PRODUCT';
        this.expireOn = new Date(Date.now());
        this.hasFee = true;
        this.isForSale = true;
        this.quantity = 0;
        this.categoryId = null;
        this.languageContent = new ItemLanguageContent();
        this.stockQuantityLimitWarning = 10;
        this.expirationPeriodLimitWarning = 10;
        this.availabilities = [ { 
            availableFrom: new Date(Date.now()), 
            availableTo: new Date(Date.now()),
            deviceId: null,
            monday: true,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
        } ];
    }
}
