import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { IgxPaginatorComponent } from 'igniteui-angular';
import { Hotel } from '../../classes/hotel';
import { DeviceService } from '../../services/device.service';
import { Router } from '@angular/router';
import { PreviewDevice } from '../../classes/preview-device';

@Component({
  selector: 'app-view-devices',
  templateUrl: './view-devices.component.html',
  styleUrls: ['./view-devices.component.scss']
})
export class ViewDevicesComponent implements OnInit, OnDestroy {

  @Input()
  public hotel: Hotel;

  @ViewChild("paginator", { static: true })
  public paginator: IgxPaginatorComponent;

  public devices: PreviewDevice[] = [];
  public deviceForDelete: PreviewDevice;
  public loading = true;
  public page: number = 0;
  public total: number = 100;
  public selectOptions: number[] = [20, 50, 100];
  public sizePerPage: number = 20;

  private refreshRef: any;

  public constructor(
    private deviceService: DeviceService,
    private router: Router
  ) { }

  public ngOnInit(): void {
    if (!this.hotel) return
    this.paginator.pageChange
      .subscribe(
        (page: number) => {
          this.page = page;
          this.getDevices();
        }
      )
    this.paginator.perPageChange
      .subscribe(
        _ => {
          this.paginator.page = 0;
        }
      )
    this.paginator.page = 0;
    this.registerAutoRefresh();
  }

  public ngOnDestroy(): void {
    clearInterval(this.refreshRef)
  }

  public registerAutoRefresh(): void {
    this.refreshRef = setInterval(() => {
      this.paginator.pageChange.emit(this.page);
    }, 10000);
  }

  public getDevices(): void {
    this.loading = true;
    this.deviceService
      .findAllByHotelAndPage(this.hotel.id, this.page, this.sizePerPage)
      .subscribe(
        res => {
          const devices = res.devices;
          this.devices = devices.sort((a, b) => a.room > b.room ? 1 : -1);
          this.loading = false;
          this.total = res.total;
        }
      );
  }

  public viewDevice(device: PreviewDevice): void {
    this.router.navigate([`${this.router.url}/device/${device.id}`])
  }

  public changeStatus(device: PreviewDevice): void {
    if (device.active) {
      this.deviceService
        .executeCommand(device.id, 'DEACTIVATE')
        .subscribe(
          res => {
            device.status = 'INACTIVE';
            device.active = false;
          }
        );
    } else {
      this.deviceService
        .executeCommand(device.id, 'ACTIVATE')
        .subscribe(
          res => {
            device.status = 'ACTIVE';
            device.active = true;
          }
        );
    }
  }

  public getBackgroundColor(device: PreviewDevice): string {
    if (device.connected && device.charging) {
      return '#009409'
    } else if (device.connected && !device.charging) {
      return '#DDE700'
    } else if (!device.connected) {
      return '#CF2200';
    }
  }

  public getDeviceLiveStatus(device: PreviewDevice): string {
    if (device.connected && device.charging) {
      return 'Connected'
    } else if (device.connected && !device.charging) {
      return 'Not charging'
    } else if (!device.connected) {
      return 'Disconnected';
    }
  }

}
