import { NgModule } from '@angular/core';

import {
    IgxListModule,
    IgxIconModule,
    IgxButtonModule,
    IgxDialogModule,
    IgxSnackbarModule,
    IgxInputGroupModule,
    IgxRippleModule,
    IgxDatePickerModule,
    IgxTimePickerModule,
    IgxComboModule,
    IgxSelectModule,
    IgxFilterModule,
    IgxProgressBarModule,
    IgxNavigationDrawerModule,
    IgxNavbarModule,
    IgxLayoutModule,
    IgxRadioModule,
    IgxSwitchModule,
    IgxToggleModule,
    IgxTooltipModule,
    IgxToastModule,
    IgxCardModule,
    IgxDividerModule,
    IgxExpansionPanelModule,
    IgxChipsModule,
    IgxTabsModule,
    IgxDropDownModule,
    IgxButtonGroupModule,
    IgxGridModule,
    IgxCheckboxModule,
} from 'igniteui-angular';


const MODULES = [
    IgxListModule,
    IgxIconModule,
    IgxButtonModule,
    IgxDialogModule,
    IgxSnackbarModule,
    IgxInputGroupModule,
    IgxRippleModule,
    IgxDatePickerModule,
    IgxTimePickerModule,
    IgxComboModule,
    IgxSelectModule,
    IgxFilterModule,
    IgxProgressBarModule,
    IgxNavigationDrawerModule,
    IgxNavbarModule,
    IgxButtonModule,
    IgxIconModule,
    IgxLayoutModule,
    IgxRadioModule,
    IgxRippleModule,
    IgxSwitchModule,
    IgxToggleModule,
    IgxTooltipModule,
    IgxToastModule,
    IgxCardModule,
    IgxDividerModule,
    IgxExpansionPanelModule,
    IgxChipsModule,
    IgxTabsModule,
    IgxDropDownModule,
    IgxButtonGroupModule,
    IgxCheckboxModule,
    IgxGridModule
];

@NgModule({
  imports: MODULES,
  exports: MODULES
})
export class IgniteModule { }
