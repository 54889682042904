import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HotelRoutingModule } from './hotel-routing.module';
import { HotelComponent } from './hotel.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { ViewHotelComponent } from './components/view-hotel/view-hotel.component';
import { EditHotelComponent } from './components/edit-hotel/edit-hotel.component';
import { CreateHotelComponent } from './components/create-hotel/create-hotel.component';
import { ViewItemsComponent } from './components/view-items/view-items.component';
import { ViewDevicesComponent } from './components/view-devices/view-devices.component';
import { ViewCategoriesComponent } from './components/view-categories/view-categories.component';
import { ViewOrdersComponent } from './components/view-orders/view-orders.component';
import { CreateCategoryComponent } from './components/create-category/create-category.component';
import { CreateItemComponent } from './components/create-item/create-item.component';
import { EditCategoryComponent } from './components/edit-category/edit-category.component';
import { EditItemComponent } from './components/edit-item/edit-item.component';
import { ViewItemComponent } from './components/view-item/view-item.component';
import { ViewDeviceComponent } from './components/view-device/view-device.component';
import { CategoryService } from './services/category.service';
import { DeviceService } from './services/device.service';
import { FunctionalityService } from './services/functionality.service';
import { HotelService } from './services/hotel.service';
import { ItemService } from './services/item.service';
import { LanguageService } from './services/language.service';
import { OrderService } from './services/order.service';
import { ViewOrderComponent } from './components/view-order/view-order.component';
import { OrderHistoryComponent } from './components/order-history/order-history.component';
import { PdfService } from './services/pdf.service';
import { ViewAvailabilitiesComponent } from './components/view-availabilities/view-availabilities.component';

@NgModule({
  declarations: [
    HotelComponent,
    ViewHotelComponent,
    EditHotelComponent,
    CreateHotelComponent,
    ViewItemsComponent,
    ViewDevicesComponent,
    ViewCategoriesComponent,
    ViewOrdersComponent,
    CreateCategoryComponent,
    CreateItemComponent,
    EditCategoryComponent,
    EditItemComponent,
    ViewItemComponent,
    ViewDeviceComponent,
    ViewOrderComponent,
    OrderHistoryComponent,
    ViewAvailabilitiesComponent
  ],
  imports: [
    CommonModule,
    HotelRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    SharedModule,
  ],
  providers: [
    CategoryService,
    DeviceService,
    FunctionalityService,
    HotelService,
    ItemService,
    LanguageService,
    OrderService,
    PdfService
  ]
})
export class HotelModule { }
