import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { ApiService } from './services/api.service';
import { UserService } from './services/user.service';
import { AppGuard } from './guards/app.guard';
import { LoginGuard } from './guards/login.guard';
import { MaterialModule } from '../material/material.module';
import { IgniteModule } from '../ignite/ignite.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedRoutingModule,
    MaterialModule,
    IgniteModule
  ],
  providers: [
    ApiService,
    UserService,
    AppGuard,
    LoginGuard
  ],
  exports: [
    IgniteModule,
    MaterialModule
  ]
})
export class SharedModule { }
