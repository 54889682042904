import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { IgxPaginatorComponent } from 'igniteui-angular';
import { Hotel } from '../../classes/hotel';
import { OrderService } from '../../services/order.service';
import { Router } from '@angular/router';
import { PreviewOrder } from '../../classes/preview-order';
import { Item } from '../../classes/item';
import { Device } from '../../classes/device';

@Component({
  selector: 'app-view-orders',
  templateUrl: './view-orders.component.html',
  styleUrls: ['./view-orders.component.scss']
})
export class ViewOrdersComponent implements OnInit, OnDestroy {

  @Input()
  public hotelId: number;
  @Input()
  public deviceId: number;
  @Input()
  public itemId: number;

  @ViewChild("paginator", { static: true })
  public paginator: IgxPaginatorComponent;

  public orders: PreviewOrder[] = [];
  public loading = true;
  public page: number = 0;
  public total: number = 100;
  public selectOptions: number[] = [20, 50, 100];
  public sizePerPage: number = 20;

  private refreshRef: any;

  public constructor(
    private orderService: OrderService,
    private router: Router
  ) { }

  public ngOnInit(): void {
    this.paginator.pageChange
      .subscribe(
        (page: number) => {
          this.page = page;
          this.getOrders();
        }
      )
    this.paginator.perPageChange
      .subscribe(
        _ => {
          this.paginator.page = 0;
        }
      )
    this.paginator.page = 0;
    this.registerAutoRefresh()
  }

  public ngOnDestroy(): void {
    clearInterval(this.refreshRef)
  }

  public registerAutoRefresh(): void {
    this.refreshRef = setInterval(() => {
      this.paginator.pageChange.emit(this.page);
    }, 10000);
  }

  private getOrders() {
    this.loading = true;
    let filter: any = {
      hotelId: this.hotelId,
      page: this.page,
      size: this.sizePerPage
    };
    if (this.itemId) filter.itemId = this.itemId
    if (this.deviceId) filter.deviceId = this.deviceId
    this.orderService
      .findAllFiltered(filter)
      .subscribe(
        res => {
          const orders = res.orders;
          this.orders = orders.sort((a, b) => a.createdOn > b.createdOn ? -1 : 1);
          this.loading = false;
          this.total = res.total;
        }
      );
  }

  public viewOrder(order: PreviewOrder): void {
    this.router.navigate([`${this.router.url}/order/${order.id}`]);
  }

  public getBackgroundColor(order: PreviewOrder): string {
    switch (order.status) {
      case 'COMPLETED':
        return '#009409';
      case 'CANCELLED':
        return '#CF2200';
      case 'UNCONFIRMED':
        return '#DDE700'
      case 'IN_PROGRESS':
        return '#008BE6';
      case 'NEW':
        return '#BFBFBF';
    }
  }

}
