<div class="wrapper">
  <igx-card *ngIf="hotel">
      <igx-card-header>
        <h3 igxCardHeaderTitle>{{hotel.name}}</h3>
        <h5 igxCardHeaderSubtitle>{{hotel.code}}</h5>
        <div igxLayout igxLayoutDir="col">
            <div>
                <igx-chip>
                    <igx-icon igxPrefix>devices</igx-icon>
                    {{hotel.nrOfDevices}} registered devices
                </igx-chip>
                <igx-chip>
                    <igx-icon igxPrefix>devices</igx-icon>
                    {{hotel.nrOfConnectedDevices}} connected devices
                </igx-chip>
                <igx-chip>
                    <igx-icon igxPrefix>devices</igx-icon>
                    {{hotel.nrOfDisconnectedDevices}} disconnected devices
                </igx-chip>
            </div>
            <div igxLayout igxLayoutJustify="flex-end">
                <igx-chip>
                    <igx-icon igxPrefix>room_service</igx-icon>
                    {{hotel.nrOfOrders}} orders
                </igx-chip>
                <igx-chip>
                    <igx-icon igxPrefix>restaurant</igx-icon>
                    {{hotel.nrOfItems}} items
                </igx-chip>
            </div>
        </div>
      </igx-card-header>

      <igx-card-content>
          <span class="category">Room Service</span>
          <div igxLayout igxLayoutDir="col">
              <div igxLayout igxLayoutJustify="space-between">
                  <span>Fee amount</span>
                  <span>Availability</span>
              </div>
              <div igxLayout igxLayoutJustify="space-between">
                  <span>€ <strong>{{hotel.feeAmount.toFixed(2)}}</strong></span>
                  <div>
                      <span><strong>{{hotel.serviceAvailableFrom}}</strong></span>
                      <span> - </span>
                      <span><strong>{{hotel.serviceAvailableTo}}</strong></span>
                  </div>
              </div>
          </div>

          <span class="category">Inventory reminders</span>
          <div igxLayout igxLayoutDir="column">
              <span>Quantity left in stock: <strong>{{hotel.stockQuantityLimitWarning}}</strong></span>
              <span>Days before expiration: <strong>{{hotel.expirationPeriodLimitWarning}}</strong></span>
          </div>

          <span class="category">Services</span>
          <div igxLayout igxLayoutJustify="space-between">
              <div igxLayout igxLayoutDir="column">
                  <span>Check-in: <strong>{{hotel.checkIn}}</strong></span>
                  <span>Check-out: <strong>{{hotel.checkOut}}</strong></span>
              </div>
              <div igxLayout igxLayoutDir="column">
                <span>Default language: <strong>{{hotel.defaultLanguage.name}}</strong></span>
                <span>Day change: <strong>{{hotel.dayChange}}</strong></span>
              </div>
          </div>

          <span class="category">Device</span>
          <div igxLayout igxLayoutDir="column">
              <span>Unlock code: {{hotel.unlockCode}} </span>
              <span>Guest App version: {{hotel.guestAppVersion}}</span>
              <span>Reception App version: {{hotel.receptionAppVersion}}</span>
          </div>
      </igx-card-content>

      <igx-divider></igx-divider>

      <igx-card-actions>
          <button igxButton igxRipple (click)="editHotel()">Edit</button>
          <button igxButton [igxToggleAction]="dropdown" [igxDropDownItemNavigation]="dropdown">Add</button>
          <igx-drop-down #dropdown [width]="'100px'">
              <igx-drop-down-item (click)="addNew('/hotel/category/new')">New Category</igx-drop-down-item>
              <igx-drop-down-item (click)="addNew('/hotel/item/new')">New Item</igx-drop-down-item>
          </igx-drop-down>
          <button igxButton igxRipple (click)="openOrderHistory()">Orders history</button>
          <button igxButton igxRipple (click)="uploadApkDialog.open()">Upload APK</button>
          <button igxButton="icon" igxRipple igxRippleCentered="true" (click)="dialog.open()">
              <igx-icon>delete</igx-icon>
          </button>
      </igx-card-actions>
  </igx-card>

  <igx-tabs class="mt-2" *ngIf="hotel" style="border-radius: 24px;" 
    [(selectedIndex)]="tabSelectedIndex"
    (selectedIndexChange)="onTabItemSelected()">
      <igx-tabs-group label="Items" icon="restaurant">
        <app-view-items [hotel]="hotel"></app-view-items>
      </igx-tabs-group>
      <igx-tabs-group label="Devices" icon="devices">
        <app-view-devices [hotel]="hotel"></app-view-devices>
      </igx-tabs-group>
      <igx-tabs-group label="Orders" icon="room_service">
        <app-view-orders [hotelId]="hotel.id"></app-view-orders>
      </igx-tabs-group>
      <igx-tabs-group label="Categories" icon="view_comfy">
        <app-view-categories [hotel]="hotel" (delete)="onDeleteCategory($event)"></app-view-categories>
      </igx-tabs-group>
  </igx-tabs>

  <igx-dialog #dialog title="Confirmation"
      leftButtonLabel="Cancel"
      (onLeftButtonSelect)="dialog.close()"
      rightButtonLabel="OK"
      (onRightButtonSelect)="deleteHotel(hotelCode.value); dialog.close()">
      <div class="p-2 delete-dialog">
          <p>Please note that this action will also delete all the functionalities, categories, items, devices and orders related to this hotel.</p>
          <p>Please type in the hotel <strong>code</strong> to confirm.</p>
          <igx-input-group>
              <label igxLabel>Code</label>
              <input igxInput #hotelCode name="code" />
          </igx-input-group>
      </div>
  </igx-dialog>

  <igx-dialog #uploadApkDialog title="Upload new APK"
      leftButtonLabel="Cancel"
      (onLeftButtonSelect)="uploadApkDialog.close()"
      rightButtonLabel="Upload"
      (onRightButtonSelect)="uploadApk(); uploadApkDialog.close()">
      <div class="p-1">
        <form [formGroup]="uploadApkRequest">
            <igx-input-group>
                <label igxLabel>Version</label>
                <input igxInput required formControlName="version" name="version"/>
            </igx-input-group>
            <igx-input-group>
                <label igxLabel>APK</label>
                <input igxInput required
                (change)="validateApk($event)" type="file"/>
            </igx-input-group>
            <igx-select required formControlName="role">
                <label igxLabel>Role</label>
                <igx-select-item value="GUEST">Guest</igx-select-item>
                <igx-select-item value="RECEPTION">Reception</igx-select-item>
            </igx-select>
        </form>
      </div>
  </igx-dialog>

  <igx-dialog #uploadedApk title="APK successfully uploaded"
      rightButtonLabel="OK"
      (onRightButtonSelect)="uploadedApk.close()">
  </igx-dialog>

  <igx-toast #toast></igx-toast>

</div>

<igx-linear-bar class="loading" *ngIf="loading" indeterminate="true"></igx-linear-bar>
