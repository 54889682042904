
<igx-select class="mt-1" *ngIf="hotel" [(ngModel)]="selectedCategory" (onSelection)="onSelectCategory($event.newSelection.value)">
  <label igxLabel>Category</label>
  <igx-select-item-group *ngFor="let functionality of hotel.functionalities" [label]="functionality.name">
    <igx-select-item
        *ngFor="let category of functionality.categories" [value]="category">
        {{category.name}}
    </igx-select-item>
  </igx-select-item-group>
</igx-select>

<igx-list cdkDropList (cdkDropListDropped)="dropItem($event)" style="height: auto;" class="mt-1">
  <ng-template igxEmptyList>
    <p class="center">There are no items found for this category.</p>
  </ng-template>
  <ng-template igxDataLoading>
    <p class="center">Patience, we are currently loading your data...</p>
  </ng-template>
  <igx-list-item
      (click)="viewItem(item)"
      cdkDrag
      *ngFor="let item of items">
    <span igxListLineTitle>{{item.name}}</span>
    <span igxListLineSubTitle>Quantity: {{item.quantity}}</span>
    <span igxListLineSubTitle>Price: € {{item.price.toFixed(2)}}</span>
    <igx-icon igxListAction (click)="$event.preventDefault(); editItem(item)" [igxTooltipTarget]="edit">edit</igx-icon>
  </igx-list-item>
  <div #edit="tooltip" igxTooltip>
      Edit
  </div>
</igx-list>
<igx-paginator #paginator
  class="mt-1"
  [selectOptions]="selectOptions"
  [page]="0"
  [totalRecords]="total"
  [(perPage)]="sizePerPage">
</igx-paginator>
