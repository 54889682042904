import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { PreviewItem } from '../classes/preview-item';
import { CreateItemRequest } from '../classes/create-item';
import { EditItemRequest } from '../classes/edit-item';
import { Item } from '../classes/item';
import { ItemsResponse } from '../classes/items.response';
import { map } from 'rxjs/operators';

@Injectable()
export class ItemService {

  public constructor(
    private apiService: ApiService,
    private userService: UserService
  ) { }

  public updateSequence(items: PreviewItem[]): Observable<any> {
    const token = this.userService.get() && this.userService.get().token || '';
    const requestItems: { itemId: number, sequence: number }[] = [];
    items.forEach((item, index) => {
      requestItems.push({ itemId: item.id, sequence: index });
    });
    return this.apiService.post('/items/sequence', { items: requestItems }, token);
  }

  public findAllByCategory(categoryId: number): Observable<PreviewItem[]> {
    return this.findAllByFilter({ categoryId })
      .pipe(
        map(res => res.items)
      );
  }

  public findAllByCategoryAndPage(
      categoryId: number, 
      page: number, 
      size: number
  ): Observable<ItemsResponse> {
    const filter = {
      categoryId,
      page,
      size
    }
    return this.findAllByFilter(filter);
  }

  public findAllByHotel(hotelId: number): Observable<PreviewItem[]> {
    return this.findAllByFilter({ hotelId })
      .pipe(
        map(res => res.items)
      );
  }

  public findAllByFilter(
    filter: {
      hotelId?: number,
      page?: number,
      size?: number,
      categoryId?: number,
      functionalityId?: number,
      availabilityDeviceId?: number,
      onlyOnStock?: boolean
    }
  ): Observable<ItemsResponse> {
    const token = this.userService.get() && this.userService.get().token || '';
    let urlPath = `/items?`;
    if (filter.hotelId) {
      urlPath = urlPath.concat(`hotelId=${filter.hotelId}&`);
    }
    if (filter.page) {
      urlPath = urlPath.concat(`page=${filter.page}&`);
    }
    if (filter.size) {
      urlPath = urlPath.concat(`size=${filter.size}&`);
    }
    if (filter.categoryId) {
      urlPath = urlPath.concat(`categoryId=${filter.categoryId}&`);
    }
    if (filter.functionalityId) {
      urlPath = urlPath.concat(`functionalityId=${filter.functionalityId}&`);
    }
    if (filter.availabilityDeviceId) {
      urlPath = urlPath.concat(`availabilityDeviceId=${filter.availabilityDeviceId}&`);
    }
    if (filter.onlyOnStock) {
      urlPath = urlPath.concat(`onlyOnStock=${filter.onlyOnStock}`)
    }
    return this.apiService.get(urlPath, token);
  }

  public create(createItemRequest: CreateItemRequest): Observable<any> {
    const token = this.userService.get() && this.userService.get().token || '';
    let item = Object.assign(new CreateItemRequest(), createItemRequest);
    item = this.prepareItemForSending(item);
    return this.apiService.post('/items', item, token);
  }

  public update(editItemRequest: EditItemRequest, id: number): Observable<any> {
    const token = this.userService.get() && this.userService.get().token || '';
    let item = Object.assign(new EditItemRequest(), editItemRequest);
    item = this.prepareItemForSending(item);
    return this.apiService.put(`/items/${id}`, item, token);
  }

  public delete(id: number): Observable<any> {
    const token = this.userService.get() && this.userService.get().token || '';
    return this.apiService.delete(`/items/${id}`, token);
  }

  public findById(id: number): Observable<Item> {
    const token = this.userService.get() && this.userService.get().token || '';
    return this.apiService.get(`/items/${id}`, token);
  }

  private prepareItemForSending(item: any): any {
    item.name = item.languageContent.nameEn;
    item.shortDescription = item.languageContent.shortDescriptionEn;
    item.longDescription = item.languageContent.longDescriptionEn;
    item.availabilities = this.createAvailabilities(item);
    item.expireOn = item.expireOn.toISOString();
    return item;
  }

  private formatHour(date: Date): string {
    const minutes =  date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;
    const hours =  date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`;
    return `${hours}:${minutes}`;
  }

  private createAvailabilities(item: any): { 
    availableFrom: string, 
    availableTo: string, 
    deviceId: string, 
    dayOfWeek: string 
  }[] {
    const availabilities = [];
    item.availabilities.forEach(a => {
      availabilities.push({
        availableFrom: this.formatHour(a.availableFrom),
        availableTo: this.formatHour(a.availableTo),
        deviceId: a.deviceId,
        monday: a.monday,
        tuesday: a.tuesday,
        wednesday: a.wednesday,
        thursday: a.thursday,
        friday: a.friday,
        saturday: a.saturday,
        sunday: a.sunday,
      });
    });
    return availabilities;
  }

}
