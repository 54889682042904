import { Component, OnInit, ViewChild } from '@angular/core';
import { CredentialsService } from './services/credentials.service';
import { IgxToastComponent } from 'igniteui-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  @ViewChild(IgxToastComponent, { static: true })
  public toast: IgxToastComponent;

  public loading = false;
  public request = { email: '', oldPassword: '', newPassword: '' };
  public oldPasswordVisibility = false;
  public newPasswordVisibility = false;

  public constructor(
    private credentialsService: CredentialsService,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    private userService: UserService,
    private router: Router
  ) { }

  public ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        const code = params.code;
        if (code) {
          this.credentialsService
            .update({code: code})
            .subscribe(
              res => {
                this.loading = false;
                this.snackbar.open('Credentials updated. Please log in', 'OK', { duration: 10000 });
                this.userService.logout();
                this.router.navigate(['/login'], { replaceUrl: true });
              },
              err => {
                this.loading = false;
                this.toast.message = err.error.message;
                this.toast.show();
              }
            );
        }
      });
    const user = this.userService.get();
    this.request.email = user.email;
  }

  public onSave(): void {
    this.credentialsService
      .requestUpdate(this.request)
      .subscribe(
        res => {
          this.loading = false;
          this.toast.message = 'Please check your email address for confirmation.';
          this.toast.show();
        },
        err => {
          this.loading = false;
          this.toast.message = err.error.message;
          this.toast.show();
        }
      );
  }

}
