<div class="wrapper">

  <igx-card>
      <igx-card-header>
          <h3 igxCardHeaderTitle>Add category</h3>
          <h5 igxCardHeaderSubtitle>{{category.name}}</h5>
      </igx-card-header>

      <igx-card-content>

          <igx-select class="mt-1 mb-1" [(ngModel)]="selectedHotel">
              <label igxLabel>Hotel</label>
              <igx-select-item *ngFor="let hotel of hotels" [value]="hotel">
                  {{hotel.name}}
              </igx-select-item>
          </igx-select>

          <form #categoryForm="ngForm">
              <igx-select required [(ngModel)]="category.functionalityId" name="functionalityId">
                  <label igxLabel>Department</label>
                  <igx-select-item *ngFor="let functionality of selectedHotel.functionalities" [value]="functionality.id">
                      {{functionality.name}}
                  </igx-select-item>
              </igx-select>
              <div class="mt-2">
                  <span class="category">Multi language</span>
              </div>
              <igx-tabs>
                  <igx-tabs-group label="EN">
                      <igx-input-group>
                          <label igxLabel>English Name</label>
                          <input igxInput required [(ngModel)]="category.languageContent.nameEn" name="nameEn" />
                      </igx-input-group>
                  </igx-tabs-group>
                  <igx-tabs-group label="FR">
                      <igx-input-group>
                          <label igxLabel>French Name</label>
                          <input igxInput required [(ngModel)]="category.languageContent.nameFr" name="nameFr" />
                      </igx-input-group>
                  </igx-tabs-group>
                  <igx-tabs-group label="IT">
                      <igx-input-group>
                          <label igxLabel>Italian Name</label>
                          <input igxInput required [(ngModel)]="category.languageContent.nameIt" name="nameIt" />
                      </igx-input-group>
                  </igx-tabs-group>
              </igx-tabs>
          </form>
      </igx-card-content>

      <igx-card-actions>
          <button igxButton igxRipple (click)="onCancel()">Cancel</button>
          <button [disabled]="categoryForm.invalid || loading" igxButton igxRipple (click)="onSave()">Save</button>
      </igx-card-actions>
  </igx-card>
  <igx-toast #toast></igx-toast>
</div>

<igx-linear-bar class="loading" *ngIf="loading" indeterminate="true"></igx-linear-bar>
