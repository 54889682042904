import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { PreviewFunctionality } from '../classes/preview-functionality';

@Injectable()
export class FunctionalityService {

  public constructor(
    private apiService: ApiService,
    private userService: UserService
  ) { }

  public findAllByHotel(id: number): Observable<PreviewFunctionality[]> {
    const token = this.userService.get() && this.userService.get().token || '';
    return this.apiService.get(`/functionalities/hotel/${id}`, token);
  }
}
