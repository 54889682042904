import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PreviewCategory } from '../../classes/preview-category';
import { Router } from '@angular/router';
import { CategoryService } from 'src/app/hotel/services/category.service';
import { Hotel } from '../../classes/hotel';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { PreviewFunctionality } from '../../classes/preview-functionality';

@Component({
  selector: 'app-view-categories',
  templateUrl: './view-categories.component.html',
  styleUrls: ['./view-categories.component.scss']
})
export class ViewCategoriesComponent implements OnInit {

  @Input()
  public hotel: Hotel;

  @Output()
  public delete = new EventEmitter<{deleted: boolean, message: string}>();

  public selectedFunctionality: PreviewFunctionality = { name: '', id: 0, categories: [] };
  public categoryForDelete: PreviewCategory;

  public constructor(
    private categoryService: CategoryService,
    private router: Router
  ) { }

  public ngOnInit(): void {
    if (!this.hotel) return
    const id = localStorage.getItem('functionality');
    const functionalities = this.hotel.functionalities;
    this.selectedFunctionality = functionalities.filter(category => category.id == Number.parseInt(id))[0];
    if (!this.selectedFunctionality) {
      this.selectedFunctionality = functionalities[0];
    }
    if (this.selectedFunctionality) {
      this.onSelectFunctionality(this.selectedFunctionality);
    }
  }

  public onSelectFunctionality(functionality: PreviewFunctionality): void {
    this.selectedFunctionality = functionality;
    localStorage.setItem('functionality', functionality.id.toString());
  }

  public editCategory(category: PreviewCategory): void {
    this.router.navigateByUrl(`/hotel/category/edit/${category.id}`);
  }

  public deleteCategory(): void {
    this.categoryService
      .delete(this.categoryForDelete)
      .subscribe(
        res => {
          this.categoryForDelete = undefined;
          this.delete.emit({deleted: true, message: 'Category successfully deleted.'});

        },
        err => {
          this.delete.emit({deleted: false, message: err.error.message});
        }
      );
  }

  public dropCategory(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedFunctionality.categories, event.previousIndex, event.currentIndex);
    this.categoryService
      .updateSequence(this.selectedFunctionality.categories)
      .subscribe(
        res => { }
      );
  }

}
