export class CategoryLanguageContent {
    nameEn: string;
    nameFr: string;
    nameIt: string;

    constructor() {
        this.nameEn = '';
        this.nameFr = '';
        this.nameIt = '';
    }
}